import { createContext } from 'react'
import { TPageData } from './TPageData'

interface TPageContext {
  pageData: TPageData | null
  pathParams: any
}

export const PageContext = createContext<TPageContext>({
  pageData: null,
  pathParams: {},
})
