import { TFilterOption } from '@renderer-ui-library/molecules'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { AvailableMachineTypes } from 'utils/machine/AvailableMachineTypes'
import { MachinePlaceholderImageMapper } from 'utils/machine/placeholder/MachinePlaceholderImageMapper'
import { useMachineTypeName } from 'utils/machine/useMachineTypeName'

export const useMachineTypeOptions = () => {
  const { getMachineTypeName } = useMachineTypeName()
  const { t } = useTranslation(localeNamespace.common)

  return useMemo<TFilterOption[]>(() => {
    const machineTypes = Object.values(AvailableMachineTypes)
    const popularMachineTypes = [
      MachineType.TRACTOR,
      MachineType.COMBINE_HARVESTER,
      MachineType.BALER,
      MachineType.POTATO_HARVESTER,
      MachineType.TELEHANDLER,
    ]

    const popularMachineType = popularMachineTypes.map((machineType) => ({
      label: getMachineTypeName(machineType, 1),
      img: MachinePlaceholderImageMapper(machineType, 'image'),
      id: machineType,
      groupBy: t(translations.searchResultsFiltersLocationPopularLabel),
    }))

    const otherMachineType = machineTypes
      .filter((machineType) => !popularMachineTypes.includes(machineType))
      .map((machineType) => ({
        label: getMachineTypeName(machineType, 1),
        id: machineType,
        groupBy: t(
          translations.searchResultsFiltersLocationOtherMachineryLabel
        ),
      }))
      .sort((a, b) => (a.label > b.label ? 1 : -1))

    return [...popularMachineType, ...otherMachineType]
  }, [getMachineTypeName, t])
}
