import { TFeatureFlagsMap } from '@internal/utils/featureFlags/TFeatureFlagsMap'
import crypto from 'crypto'
import { createContext, FC, memo, PropsWithChildren, useMemo } from 'react'
import { Settings } from './SettingsContext'

const computeRollout = (percent: number, ip: string | null) => {
  if (percent <= 0 || !ip) {
    return false
  }

  if (percent >= 100) {
    return true
  }

  const hash = crypto.createHash('sha256').update(ip).digest('hex')
  const hashValue = parseInt(hash.substring(0, 8), 16) // Convert first 8 chars to an integer

  return hashValue % 100 < percent
}

type Value = {
  brandSelectOn: boolean
  modelSelectOn: boolean
}
export const RolloutContext = createContext<Value>({
  brandSelectOn: false,
  modelSelectOn: false,
})

export const RolloutProvider: FC<
  PropsWithChildren<{
    settings: Settings
    ip: string | null
    featureFlags: TFeatureFlagsMap
  }>
> = memo(({ children, settings, ip, featureFlags }) => {
  const value = useMemo(
    (): Value => ({
      brandSelectOn:
        featureFlags.brandSelect || computeRollout(settings.rollout1, ip),
      modelSelectOn:
        featureFlags.modelSelect || computeRollout(settings.rollout2, ip),
    }),
    [
      featureFlags.brandSelect,
      featureFlags.modelSelect,
      settings.rollout1,
      settings.rollout2,
      ip,
    ]
  )
  return (
    <RolloutContext.Provider value={value}>{children}</RolloutContext.Provider>
  )
})

RolloutProvider.displayName = 'RolloutProvider'
