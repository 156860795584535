import { TLocalizedUrlMap } from '@internal/i18n/TLocalizedUrlMap'
import { Machine } from '@internal/utils/machine/Machine'
import { createContext } from 'react'
import { getLocalizedUrlMapForStartPage } from '../routing/urls/getLocalizedUrlMapForStartPage'

type TMachineContext = {
  machine: Machine | null
  localizedUrlMap: TLocalizedUrlMap | null
  updateMachine: (machine: Machine | null) => void
}

export const MachineContext = createContext<TMachineContext>({
  machine: null,
  localizedUrlMap: getLocalizedUrlMapForStartPage(),
  updateMachine: () => {},
})
