import { ArrowForward } from '@mui/icons-material'
import { Button, Text } from '@renderer-ui-library/atoms'
import {
  ContactUsButton,
  TButtonAction,
  TButtonData,
} from '@renderer-ui-library/molecules'
import { Typography } from '@renderer-ui-library/mui'
import { ignoreColumnCtaClickClass } from '@renderer-ui-library/mui/base/constants'
import classNames from 'classnames'
import Link from 'next/link'
import React, { useMemo } from 'react'
import { pathContainsClassName } from 'utils/dom/pathContainsClassName'
import { usePrioritizedUrl } from 'utils/routing/urls/usePrioritizedUrl'
import styles from './mediaColumn.module.scss'

type Props = {
  id: string
  title?: string
  content: string
  cta?: null | TButtonData
  showInfo: boolean
  children: React.ReactNode
  orientation?: 'column' | 'row'
  noMarginBottom?: boolean
  highlight?: boolean
  topTitle?: string | null
}

const ignoreClick = (ev: React.MouseEvent<HTMLAnchorElement>) => {
  if (
    pathContainsClassName(ignoreColumnCtaClickClass, ev.target as HTMLElement)
  ) {
    ev.preventDefault()
  }
}

export const MediaColumn: React.FC<Props> = React.memo((props) => {
  const { getPrioritizedUrl } = usePrioritizedUrl()
  const url = props.cta && getPrioritizedUrl(props.cta)
  const showCta = !!(props.cta && url)
  const isRow = props.orientation === 'row'
  const isCtaWithContactUsAction =
    props.cta?.action === TButtonAction.openContactUsForm

  const renderContent = useMemo(
    () => (
      <>
        {props.children}
        {props.showInfo && (
          <div className={styles.item}>
            <div className={styles.linkContent}>
              {props.title && (
                <Typography
                  variant='h6'
                  color={props.highlight ? 'white' : 'textSecondary'}
                  gutterBottom
                >
                  {props.title}
                </Typography>
              )}
              {props.content && (
                <div
                  className={classNames(styles.text, {
                    [styles.noMarginBottom]: props.noMarginBottom,
                  })}
                >
                  <Text
                    text={props.content}
                    highlight={props.highlight}
                    color={props.highlight ? 'bright' : 'dark'}
                  />
                </div>
              )}
            </div>
            {isCtaWithContactUsAction ? (
              <ContactUsButton
                text={props.cta!.text}
                color={props.highlight ? 'secondary' : 'primary'}
                variant='text'
                size='small'
                noMinWidth
              />
            ) : (
              showCta && (
                <Button
                  variant='text'
                  size='small'
                  noMinWidth
                  color={props.highlight ? 'secondary' : 'primary'}
                  endIcon={<ArrowForward />}
                >
                  {props.cta!.text}
                </Button>
              )
            )}
          </div>
        )}
      </>
    ),
    [
      isCtaWithContactUsAction,
      props.children,
      props.content,
      props.cta,
      props.showInfo,
      props.highlight,
      props.noMarginBottom,
      props.title,
      showCta,
    ]
  )

  return (
    <>
      {isCtaWithContactUsAction ? (
        <div
          className={classNames(styles.content, {
            [styles.rowContent]: isRow,
          })}
        >
          {renderContent}
        </div>
      ) : showCta ? (
        <Link
          href={url}
          passHref
          prefetch={false}
          onClick={ignoreClick}
          className={classNames(styles.link, {
            [styles.rowContent]: isRow,
          })}
        >
          {renderContent}
        </Link>
      ) : (
        <div
          className={classNames(styles.content, {
            [styles.rowContent]: isRow,
          })}
        >
          {renderContent}
        </div>
      )}
    </>
  )
})

MediaColumn.displayName = 'MediaColumn'
