import { MachineType } from '@website-shared-library/machine/MachineType'
import axios from 'axios'
import { environment } from 'environment/environment'
import { uniq } from 'lodash'

const machineSearchClient = axios.create({
  baseURL: `${environment.MACHINE_SEARCH_URL}/api`,
  timeout: 30000,
})

type ModelSearchResult = {
  BRAND: string
  BASE_MODEL: string
  MODEL: string
  MODEL_EXTENSION?: string
  MODEL_SERIES?: string
  MACHINE_TYPE?: MachineType
}

export const searchBrands = (query: string, abortSignal?: AbortSignal) => {
  return machineSearchClient
    .get<string[]>(`/v1/brands/search`, {
      signal: abortSignal,
      params: {
        query,
        limit: 20,
      },
    })
    .then((res) => res.data)
    .then((brands) => brands as string[])
    .catch((e) => [])
}

export const searchModels = (
  query: string,
  brand?: string,
  abortSignal?: AbortSignal
) => {
  return machineSearchClient
    .get<ModelSearchResult[]>(`/v1/models/autocomplete`, {
      signal: abortSignal,
      params: {
        query,
        BRAND: brand,
        limit: 50,
      },
    })
    .then((res) => res.data)
    .then((models) => uniq(models.map((m) => [m.BASE_MODEL, m.MODEL]).flat()))
    .catch((e) => [])
}
