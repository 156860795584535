import dynamic from 'next/dynamic'
import { BlockProps } from '../BlockProps'
import { ICompareMachinesBlockData } from './ICompareMachineBlockData'
import { TServerSideCompareMachinesBlockProps } from './TServerSideCompareMachinesBlockProps'

export const DynamicCompareMachinesBlock = dynamic<
  BlockProps<
    ICompareMachinesBlockData,
    {},
    TServerSideCompareMachinesBlockProps
  >
>(() => import('./CompareMachinesBlock').then((md) => md.CompareMachinesBlock))
