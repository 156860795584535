import {
  MachineType,
  SubTypes,
} from '@website-shared-library/machine/MachineType'
import { TFilterableBaleChamberOption } from '@website-shared-library/search/TFilterableBalerChamberOption'
import { TFilterableHeaderTypeOption } from '@website-shared-library/search/TFilterableHeaderTypeOption'
import { TFilterableMachineAttribute } from '@website-shared-library/search/TFilterableMachineAttribute'
import { SearchResultsSortKey } from './SearchResultsSortKey'
import { TRangeFiltersMap } from './TRangeFiltersMap'

export type SearchModel = {
  name: string
  isMapped: boolean
}

export const mappedModelSuffix = '-mapped'

export type TSearchParams = {
  model?: SearchModel | undefined
  brands: string[]
  machineType: MachineType | undefined
  machineSubType: SubTypes[keyof SubTypes] | undefined
  baleChamber: TFilterableBaleChamberOption | undefined
  headerType: TFilterableHeaderTypeOption | undefined
  amount: number
  features: TFilterableMachineAttribute[]
  rangeFilters: TRangeFiltersMap
  locations: string[]
  sort: SearchResultsSortKey
  excludeWithoutMedia?: boolean
}
