export const timerCountdown = (endDate: Date) => {
  const now = new Date()
  const timeToEnd = endDate.getTime() - now.getTime()
  const seconds = 1000
  const minutes = seconds * 60
  const hours = minutes * 60
  const days = hours * 24
  const timeToEndDays = Math.floor(timeToEnd / days)
  const timeToEndHours = Math.floor((timeToEnd % days) / hours)
  const timeToEndMinutes = Math.floor((timeToEnd % hours) / minutes)

  return {
    days: timeToEndDays,
    hours: timeToEndHours,
    minutes: timeToEndMinutes,
  }
}
