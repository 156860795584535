import { SystemPageKey } from 'page/SystemPageKey'
import { urlFor } from '../urls/urlFor'
import { TRedirectGenerator } from './TRedirectGenerator'
import { urlForSystemPageWithoutParams } from '../urls/urlForSystemPageWithoutParams'

export const getRedirectForUseFarmMachinery: TRedirectGenerator = async ({
  locale,
  queryParams,
}) => {
  if (Object.keys(queryParams).length === 0) {
    return urlForSystemPageWithoutParams(locale, SystemPageKey.StartPage)
  }
  return false
}
