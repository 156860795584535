import { getResponsiveSmallMediaSrc } from '@internal/utils/media/getMediaSrc'
import { BlockTopText, MediaColumn } from '@renderer-ui-library/molecules'
import { Grid } from '@renderer-ui-library/mui'
import { BlockWrapper } from '@renderer-ui-library/scaffolding/BlockWrapper/BlockWrapper'
import { BlockProps } from 'blocks/BlockProps'
import { memo } from 'react'
import { IMediaGridBlockData } from './IMediaGridBlockData'
import styles from './mediaGridBlock.module.scss'

export const MediaGridBlock: React.FC<BlockProps<IMediaGridBlockData>> = memo(
  (props) => (
    <>
      <BlockWrapper blockData={props.data}>
        {(props.data.topText || props.data.topTitle) && (
          <BlockTopText title={props.data.topTitle} text={props.data.topText} />
        )}
        <div className={styles.displayMobile}>
          <Grid container>
            {props.data.columns.map((column) => (
              <Grid
                item
                xs={4}
                md={3}
                key={column.id}
                className={styles.gridItem}
              >
                <div className={styles.mediaContainer}>
                  <MediaColumn
                    id={column.id}
                    title={column.title}
                    content={column.content}
                    cta={column.cta}
                    showInfo={false}
                    noMarginBottom={true}
                    topTitle={props.data.topTitle}
                  >
                    {column.media && (
                      <div className={styles.image}>
                        {column.media.files.data.map((file, index) => (
                          <img
                            key={index}
                            alt=''
                            {...getResponsiveSmallMediaSrc(
                              { data: { ...file } },
                              {
                                ratio: 3 / 2,
                              }
                            )}
                          />
                        ))}
                      </div>
                    )}
                  </MediaColumn>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </BlockWrapper>
    </>
  )
)

MediaGridBlock.displayName = 'MediaGridBlock'
