export enum FormType {
  ContactUs = 'Contact_Us',
  RequestProductInformation = 'Request_Product_Information',
  RequestInspection = 'Request_Inspection',
  RequestCallback = 'Request_Callback',
  RequestNewsletter = 'Request_Newsletter',
  RequestLeasing = 'Request_Leasing',
}

export interface IGAFormFields {
  GA_Client_ID?: string;
}

export interface IFormBase extends IGAFormFields {
  formName: FormType;
  locale: string;
  pageSource: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  eFarmId?: string;
  message?: string;
  searchRequestId?: string;
  formTopics?: string;
  newsletter?: boolean;
  smsAgreement?: boolean;
  country?: string;
}

export interface IContactForm extends IFormBase {
  formName: FormType.ContactUs;
  budget?: string;
  timing?: string;
  reason?: string;
  searchRequestId?: string;
}

export interface IRequestProductInfoForm extends IFormBase {
  formName: FormType.RequestProductInformation;
  eFarmId: string;
  message: string;
  searchRequestId?: string;
}

export interface IRequestInspectionForm extends IFormBase {
  formName: FormType.RequestInspection;
  eFarmId: string;
  searchRequestId?: string;
}

export interface IRequestLeasingForm extends IFormBase {
  formName: FormType.RequestLeasing;
  eFarmId: string;
  searchRequestId?: string;
}

export interface ICallbackRequestForm extends IFormBase {
  formName: FormType.RequestCallback;
  topic: string;
  dealerId: string;
  slackUserId: string;
  additionalMentionsSlackUserIds?: string[];
  searchRequestId?: string;
}

export interface INewsletterForm extends Omit<IFormBase, 'phoneNumber' | 'formName' | 'newsletter' | 'smsAgreement'> {
  formName: FormType.RequestNewsletter;
  ip?: string | undefined;
}

export type WebsiteForm =
  | IContactForm
  | IRequestProductInfoForm
  | IRequestInspectionForm
  | ICallbackRequestForm
  | INewsletterForm
  | IRequestLeasingForm;
