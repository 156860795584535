import { Locale } from '@website-shared-library/machine/i18n/Locale'
import { useRouter } from 'next/router'
import { PageContext } from 'page/PageContext'
import { SystemPageKey } from 'page/SystemPageKey'
import { useContext, useMemo } from 'react'
import { MachineContext } from 'utils/machine/MachineContext'
import { PageTypeKey } from 'utils/page/PageTypeKey'
import { useMachineUrl } from 'utils/routing/hooks/useMachineUrl'
import { getLocalizedUrlMapForStartPage } from 'utils/routing/urls/getLocalizedUrlMapForStartPage'
import { urlFor } from 'utils/routing/urls/urlFor'
import { TLocalizedUrlMap } from './TLocalizedUrlMap'

export const useLocalizedUrlMap = () => {
  const {
    query: { slugs, ...queryParams },
  } = useRouter()
  const { pageData } = useContext(PageContext)
  const { machine, localizedUrlMap: machineUrls } = useContext(MachineContext)
  const { getMachineUrl } = useMachineUrl()

  const localizations = useMemo(() => {
    let map: TLocalizedUrlMap = {
      ...getLocalizedUrlMapForStartPage(),
    }

    if (!pageData) {
      return map
    }

    switch (pageData.type) {
      case PageTypeKey.MachineCategoryPage:
      case PageTypeKey.Page: {
        map = Object.assign({}, map, pageData.localizations)
        break
      }
      case PageTypeKey.SystemPage: {
        if (pageData.key === SystemPageKey.PDP && machineUrls) {
          map = machineUrls
        } else {
          Object.values(Locale).forEach((locale) => {
            try {
              switch (pageData.key) {
                case SystemPageKey.SearchResults: {
                  map[locale] = urlFor(locale, {
                    key: SystemPageKey.SearchResults,
                    pathParams: {},
                    queryParams,
                  })
                  break
                }

                // this is a fallback in case we have a machine but no machineUrls
                case SystemPageKey.PDP: {
                  if (machine) {
                    map[locale] = getMachineUrl(locale, machine)
                  }
                  break
                }
              }
            } catch (err) {
              //ignore
            }
          })
        }
        break
      }
    }

    return map
  }, [pageData, machineUrls, queryParams, machine, getMachineUrl])

  return localizations
}
