import { AddOutlined } from '@mui/icons-material'
import { Button } from '@renderer-ui-library/atoms'
import { SvgIcon, Typography } from '@renderer-ui-library/mui'
import Colors from '@renderer-ui-library/mui/base/colors.module.scss'
import { Service, ServiceTypes } from 'blocks/ServiceBlock/IServiceBlockData'
import classNames from 'classnames'
import React, { useCallback } from 'react'
import { ClickEventTargetName } from 'utils/tracking/TTrackingEvent'
import { tracker } from 'utils/tracking/tracker'
import styles from './serviceBlockButton.module.scss'
import { ServiceEntries } from './serviceEntries'

interface ServiceBlockButtonValueProp {
  service: Service
  color: 'light' | 'dark'
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    service: Service
  ) => void
}

const clickEvents: Record<ServiceTypes, ClickEventTargetName> = {
  [ServiceTypes.Inspection]: 'service_block.inspection',
  [ServiceTypes.Warranty]: 'service_block.warranty',
  [ServiceTypes.Transport]: 'service_block.transport',
  [ServiceTypes.Payment]: 'service_block.payment',
  [ServiceTypes.Financing]: 'service_block.financing',
}

export const ServiceBlockButtonValueProp: React.FC<ServiceBlockButtonValueProp> =
  React.memo(({ service, color, onClick }) => {
    const { icon } = ServiceEntries[service.serviceType]

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onClick(event, service)
        tracker.trackEvent({
          name: 'click',
          target_name: clickEvents[service.serviceType],
          is_interaction: true,
        })
      },
      [onClick, service]
    )

    return (
      <Button
        flex
        onClick={handleClick}
        className={classNames(styles.valuePropButton, {
          [styles.dark]: color === 'dark',
        })}
      >
        <div className={styles.valuePropWrapper}>
          <SvgIcon component={icon} className={styles.valuePropIcon} />
          <div className={styles.valuePropCtaWrapper}>
            <Typography color={Colors.pageBackground} variant='h6'>
              {service.ctaTitle}
            </Typography>
            <Typography color={Colors.primary} variant='body2'>
              {service.ctaDescription}
            </Typography>
          </div>
          <AddOutlined
            color='secondary'
            fontSize='small'
            className={classNames(styles.addIcon)}
          />
        </div>
      </Button>
    )
  })

ServiceBlockButtonValueProp.displayName = 'ServiceBlockButtonValueProp'
