import { getMediaSrc } from '@internal/utils/media/getMediaSrc'
import { RatioContainer } from '@renderer-ui-library/atoms'
import classnames, { default as classNames } from 'classnames'
import { useMemo } from 'react'
import { ImageSlider } from '../ImageSlider'
import { TMediaData } from './TMediaData'
import styles from './media.module.scss'
import { DynamicYoutubeVideo } from './youtube/dyanmicYouTubeVideo'

type Props = {
  media: TMediaData
  ratio: 'original' | number
  autoPlayVideo?: boolean
  autoPlaySlideshow?: boolean
  cover?: boolean
  desktopViewportPrecentage?: number
  loading?: 'lazy' | 'eager' | undefined
}
export const Media: React.FC<Props> = ({
  media,
  ratio,
  autoPlayVideo,
  autoPlaySlideshow,
  cover,
  desktopViewportPrecentage,
  loading = 'lazy',
}) => {
  const displayRatio = ratio !== 'original' && media.videoUrl ? 16 / 9 : ratio

  const mediaWrapper = useMemo(() => {
    if (media.videoUrl) {
      return (
        <div className={styles.wrapper}>
          <DynamicYoutubeVideo
            url={media.videoUrl}
            autoPlayVideo={autoPlayVideo}
            className={classNames(styles.video, {
              [styles.videoCoverMode]: displayRatio === 'original',
            })}
          />
        </div>
      )
    }
    if (media.files.data.length > 1) {
      return (
        <ImageSlider
          images={media.files.data}
          ratio={displayRatio}
          autoPlay={autoPlaySlideshow}
          pagination={!autoPlaySlideshow}
          arrows={false}
        />
      )
    }
    return (
      <div
        className={styles.wrapper}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {media.files.data.map((file) => (
          <div key={file.id}>
            <figure>
              <img
                loading={loading}
                className={classnames(styles.media, {
                  [styles.cover]: cover,
                })}
                alt={file.attributes.alternativeText}
                {...getMediaSrc(
                  { data: file },
                  {
                    ratio: displayRatio,
                    desktopViewportPrecentage,
                  }
                )}
              />
            </figure>
            {media.imageCaption && (
              <figcaption className={styles.figCaption}>
                {media.imageCaption}
              </figcaption>
            )}
          </div>
        ))}
      </div>
    )
  }, [
    autoPlaySlideshow,
    autoPlayVideo,
    cover,
    desktopViewportPrecentage,
    displayRatio,
    loading,
    media.files.data,
    media.imageCaption,
    media.videoUrl,
  ])

  if (displayRatio === 'original' || media.files.data?.length > 1) {
    return mediaWrapper
  }

  return (
    <RatioContainer ratio={displayRatio} flex>
      {mediaWrapper}
    </RatioContainer>
  )
}
