import { ICardBannerBlockData } from '@internal/blocks/CardBannerBlock/ICardBannerBlockData'
import { TPagePropsMetaData } from '@internal/page/TPagePropsMetaData'
import { LocationProps } from '@internal/utils/geoLocation/getGeoLocation'
import { BlocksContent } from '@strapi/blocks-react-renderer'
import { LocaleEnum } from '@website-shared-library/machine/i18n/Locale'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import { Block } from 'blocks/Block'
import { TBlockData } from 'blocks/TBlockData'
import { TPreFetchedBlockProps } from 'page/TPreFetchedBlockProps'
import { ParsedUrlQuery } from 'querystring'
import React from 'react'

type Props = {
  blocks: TBlockData[]
  locale: TLocale
  preFetchedBlockProps: TPreFetchedBlockProps
  customerServiceNumbers: { [key in LocaleEnum]: string }
  location: LocationProps
  cardBannerBlock?: ICardBannerBlockData
  seoDescription?: BlocksContent | null
  pagePropsMetaData: TPagePropsMetaData
}

export const Blocks: React.FC<Props> = React.memo((props) => (
  <>
    {props.blocks.map((block, index) => (
      <Block
        key={`${block.id}-${block.type}-${index}`}
        rank={index}
        data={block}
        locale={props.locale}
        preFetchedBlockProps={props.preFetchedBlockProps[block.id] ?? {}}
        customerServiceNumbers={props.customerServiceNumbers}
        location={props.location}
        cardBannerBlock={props.cardBannerBlock}
        seoDescription={props.seoDescription}
        pagePropsMetaData={props.pagePropsMetaData}
      />
    ))}
  </>
))

Blocks.displayName = 'Blocks'
