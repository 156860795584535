export const localesMap = {
  en: 'en',
  de: 'de',
  fr: 'fr',
  el: 'el',
  it: 'it',
  pl: 'pl',
  ro: 'ro',
  ru: 'ru',
  hu: 'hu',
  es: 'es',
  bg: 'bg',
  sl: 'sl',
  sr: 'sr',
  da: 'da',
  cs: 'cs',
  lt: 'lt',
};

type TEqualKeyValueMap<T> = { [K in keyof T]: K };
export const Locale = localesMap as TEqualKeyValueMap<typeof localesMap>;

export enum localeNamespace {
  common = 'common',
}

export enum LocaleEnum {
  en = 'en',
  de = 'de',
  fr = 'fr',
  el = 'el',
  it = 'it',
  pl = 'pl',
  ro = 'ro',
  ru = 'ru',
  hu = 'hu',
  es = 'es',
  bg = 'bg',
  sl = 'sl',
  sr = 'sr',
  da = 'da',
  cs = 'cs',
  lt = 'lt',
}

export type TLocale = keyof typeof LocaleEnum;
