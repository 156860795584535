import { searchBrands } from '@internal/services/machineSearchClient'
import { TFilterOption } from '@renderer-ui-library/molecules'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { BrandImageMapper } from 'utils/brand/BrandImageMapper'

import { KnownBrands } from '@website-shared-library/machine/KnownBrands'
import { MachineType } from '@website-shared-library/machine/MachineType'
import React, { useCallback, useMemo } from 'react'
import { RemoteAutoComplete } from '../RemoteAutocomplete/RemoteAutoComplete'
import { SelectFilterOption } from '../RemoteAutocomplete/SelectFilterOption'

type Props = {
  selectedMachineType?: MachineType
  selectedBrands: TFilterOption[]
  onChange: (brands: TFilterOption[]) => void
  disabled?: boolean
}

export const BrandSelectFilter: React.FC<Props> = React.memo(
  ({ selectedMachineType, selectedBrands, onChange, disabled }) => {
    const { t } = useTranslation(localeNamespace.common)

    const values = useMemo(
      () =>
        selectedBrands.map(
          (b): SelectFilterOption => ({
            value: b.id,
            label: b.label,
          })
        ),
      [selectedBrands]
    )

    const handleChange = useCallback(
      (values: SelectFilterOption[]) =>
        onChange(values.map((v) => ({ id: v.value, label: v.label }))),
      [onChange]
    )

    const loadValues = useCallback(
      (input: string, abortSignal: AbortSignal) =>
        searchBrands(input, abortSignal).then((brands) =>
          brands.map<SelectFilterOption>((brand) => ({
            value: brand,
            label: brand,
          }))
        ),
      []
    )

    const initialValues = useMemo(() => {
      if (selectedMachineType !== MachineType.TRACTOR) {
        return undefined
      }
      return [
        KnownBrands.Fendt,
        KnownBrands.Claas,
        KnownBrands.New_Holland,
        KnownBrands.Case_IH,
      ].map(
        (brand): SelectFilterOption => ({
          value: brand,
          label: brand,
          group: t(translations.searchResultsFiltersLocationPopularLabel),
          imageUrl: BrandImageMapper(brand).attributes.url,
        })
      )
    }, [t, selectedMachineType])

    return (
      <RemoteAutoComplete
        type='multiple'
        value={values}
        onChange={handleChange}
        loadValues={loadValues}
        disabled={disabled}
        initialValues={initialValues}
        fallbackValues={initialValues}
        grouping
        inputPlaceholder={t(translations.searchCoverBlockBrandLabel)}
        freeSolo
      />
    )
  }
)

BrandSelectFilter.displayName = 'BrandSelectFilter'
