import { useMachineTypeName } from '@internal/utils/machine/useMachineTypeName'
import { PageTypeKey } from '@internal/utils/page/PageTypeKey'
import { urlFor } from '@internal/utils/routing/urls/urlFor'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import React, { useCallback, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { TNavigation } from 'utils/navigation/TNavigation'
import { TPageType } from 'utils/page/TPageType'
import { DynamicMobileMenu } from '../MobileMenu/dynamicMobileMenu'
import { AuctionBanner } from './GlobalBanner/AuctionBanner'
import { HolidayBanner } from './HolidayBanner/HolidayBanner'
import { MainNavigation } from './MainNavigation/MainNavigation'
import { SalesAgentAwayBanner } from './SalesAgentAwayBanner/SalesAgentAwayBanner'
import { TSearchParams } from './SearchOverlay/SearchOverlay'
import { SecondaryNavigation } from './SecondaryNavigation/SecondaryNavigation'
import { DynamicUSPHeader } from './USPHeader/dynamicUSPHeader'
import styles from './header.module.scss'

export interface Props {
  headerNavigation: TNavigation
  brandsNavigation: TNavigation
  secondaryNavigation: TNavigation
  servicesNavigation: TNavigation
  infoNavigation: TNavigation
  locale: TLocale
  lightBackground?: boolean
  searchParams: TSearchParams
  pageType: TPageType | null
}

export const USPHeaderGlobalID = 'USPHeaderGlobalID'

export const Header: React.FC<Props> = React.memo((props) => {
  const { isTablet } = useMediaQuery()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const showMobileMenu = useCallback(() => setMobileMenuOpen(true), [])
  const hideMobileMenu = useCallback(() => setMobileMenuOpen(false), [])
  const { locale } = props
  const { getMachineTypeName } = useMachineTypeName()
  const allOffersUrl = useMemo(
    () =>
      urlFor(locale, {
        key: PageTypeKey.MachineCategoryPage,
        machineTypeName: getMachineTypeName(MachineType.TRACTOR),
      }),
    [getMachineTypeName, locale]
  )

  return (
    <>
      <div className={styles.spacer} />
      <header className={styles.header}>
        <div className={styles.appendHeader}>
          <DynamicUSPHeader />
        </div>
        <SecondaryNavigation
          locale={props.locale}
          navigation={props.secondaryNavigation}
          searchParams={props.searchParams}
        />
        <MainNavigation
          headerNavigation={props.headerNavigation}
          brandsNavigation={props.brandsNavigation}
          servicesNavigation={props.servicesNavigation}
          locale={props.locale}
          onMobileMenuClick={showMobileMenu}
          searchParams={props.searchParams}
          allOffersUrl={allOffersUrl}
        />
      </header>
      {false && <AuctionBanner locale={props.locale} />}
      {false && <HolidayBanner />}
      {false && (
        <SalesAgentAwayBanner
          name='Istvan'
          from={new Date('2024, 1, 31')}
          to={new Date('2024, 2, 19')}
        />
      )}
      {isTablet &&
        createPortal(
          <DynamicMobileMenu
            open={mobileMenuOpen}
            onCloseClick={hideMobileMenu}
            headerNavigation={props.headerNavigation}
            brandsNavigation={props.brandsNavigation}
            secondaryNavigation={props.secondaryNavigation}
            servicesNavigation={props.servicesNavigation}
            infoNavigation={props.infoNavigation}
            locale={props.locale}
            allOffersUrl={allOffersUrl}
          />,
          document.body
        )}
    </>
  )
})

Header.displayName = 'Header'
