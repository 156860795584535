import { ActionableButton, UIButtonGroup } from '@renderer-ui-library/molecules'
import React from 'react'
import { TButtonData } from './TButtonData'
import styles from './buttonGroup.module.scss'

type Props = {
  primaryButton?: TButtonData | null
  secondaryButton?: TButtonData | null
  alignment?: 'left' | 'center'
  highlight?: boolean
}

export const ButtonGroup: React.FC<Props> = React.memo(
  ({ primaryButton, secondaryButton, alignment, highlight }) => {
    if (!primaryButton && !secondaryButton) {
      return null
    }

    return (
      <UIButtonGroup alignment={alignment}>
        <ActionableButton
          button={primaryButton}
          className={styles.button}
          color='secondary'
          variant='outlined'
        />
        <ActionableButton
          button={secondaryButton}
          className={styles.button}
          color={highlight ? 'secondary' : 'primary'}
          variant='outlined'
        />
      </UIButtonGroup>
    )
  }
)

ButtonGroup.displayName = 'ButtonGroup'
