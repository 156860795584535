import dynamic from 'next/dynamic'
import { BlockProps } from '../BlockProps'
import { IMachineryFeedSliderData } from './IMachineryFeedSliderData'
import { TServerSideMachineryFeedSliderProps } from './TServerSideMachineryFeedSliderProps'

export const DynamicMachineryFeedSliderBlock = dynamic<
  BlockProps<IMachineryFeedSliderData, {}, TServerSideMachineryFeedSliderProps>
>(() =>
  import('./MachineryFeedSlider').then((md) => md.MachineryFeedSliderBlock)
)
