import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableProps,
  TableRow,
  Typography,
} from '@renderer-ui-library/mui'
import { CollapseArea } from '../CollapseArea/CollapseArea'
import { TTableRowValue } from './TTableRowValue'
import { TableValueContent } from './TableValueContent'
import styles from './table.module.scss'

export type TTableRow = {
  key: string
  value: TTableRowValue
  marker?: boolean
}

export type OwnProps = {
  rows: TTableRow[]
  title?: string
  collapsible?: boolean
}

export type TTableProps = TableProps & OwnProps

export const Table: React.FC<TTableProps> = ({
  title,
  rows,
  collapsible,
  ...TableProps
}) => {
  const TableContent = (
    <MuiTable {...TableProps} size='small'>
      <TableBody className={styles.tableBody}>
        {rows.map((row, index) => (
          <TableRow key={`${row.key}-${index}`}>
            <TableCell width='50%'>{row.key}</TableCell>
            <TableCell className={styles.rightColumn}>
              <TableValueContent value={row.value} marker={row.marker} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </MuiTable>
  )

  return (
    <>
      {collapsible ? (
        <CollapseArea title={title} small>
          {TableContent}
        </CollapseArea>
      ) : (
        <>
          {title && (
            <div className={styles.titleRow}>
              <Typography variant='subtitle1'>{title}</Typography>
            </div>
          )}
          {TableContent}
        </>
      )}
    </>
  )
}
