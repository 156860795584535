import { Machine, MachineDataAttribute } from '@internal/utils/machine/Machine'
import { TTableRow, TTableRowValue } from '@renderer-ui-library/molecules'
import { DisplayDefinitionEntryType } from '@website-shared-library/displayDefinition/entry/DisplayDefinitionEntryType'
import { TDisplayDefinitionEntry } from '@website-shared-library/displayDefinition/entry/TDisplayDefinitionEntry'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import { displayDefinitionEntryToString } from 'utils/displayDefinition/displayDefinitionEntryToString'

type TTableRows = Omit<TTableRow, 'value'> & {
  values: TTableRowValue[]
}

export const mapMachinesAttributesToTableRow = (
  machines: Machine[],
  entries: TDisplayDefinitionEntry[],
  locale: TLocale
): TTableRows[] => {
  return entries.map((entry) => {
    const attribute = entry.machineAttribute as MachineDataAttribute
    // this has been built in a way that it only check for an unconfirmed attribute for one of the machines and takes that for all machines
    // this should be changed to check for each machine individually
    const isUnconfirmedData = !!machines.reduce(
      (result, machine) => result || !!machine.unconfirmedAttributes[attribute],
      false
    )

    switch (entry.type) {
      case DisplayDefinitionEntryType.Boolean: {
        return {
          marker: isUnconfirmedData,
          key: entry.label,
          values: machines.map((machine) => {
            const value = machine.data[attribute]
            return typeof value === 'boolean' ? value : '-'
          }),
        }
      }
      case DisplayDefinitionEntryType.Date:
      case DisplayDefinitionEntryType.String:
      case DisplayDefinitionEntryType.Number:
      case DisplayDefinitionEntryType.Enum:
      case DisplayDefinitionEntryType.EnumList: {
        return {
          key: entry.label,
          marker: isUnconfirmedData,
          values: machines.map((machine) => {
            const value = machine.data[attribute]
            return value !== undefined
              ? displayDefinitionEntryToString(
                  locale,
                  machine,
                  entry,
                  ', ',
                  true
                )
              : '-'
          }),
        }
      }
    }
  })
}
