import { classToPlain, plainToClass } from 'class-transformer';
import { EFinancingLogo } from './EFinancingLogo';
import { MachineType } from './MachineType';
import { TMachine } from './TMachine';
import { TMachineData } from './TMachineData';
import { TMachineMedia } from './TMachineMedia';
import { TSerializable } from './TSerializable';

//@StaticImplements<TDeserializable<TMachine, Machine>>()
export class Machine implements TMachine, TSerializable<TMachine> {
  data: TMachineData = {};
  media: TMachineMedia[] = [];
  leasingQualified = false;
  leasingInfo?: {
    countryCode:
      | 'en'
      | 'de'
      | 'fr'
      | 'el'
      | 'it'
      | 'pl'
      | 'ro'
      | 'ru'
      | 'hu'
      | 'es'
      | 'bg'
      | 'sl'
      | 'sr'
      | 'da'
      | 'cs'
      | 'lt';
    logo: EFinancingLogo;
    pmt: number;
  } | null;

  get machineGroupId(): string {
    return (this.data.MACHINE_GROUP_ID as string) ?? '';
  }

  get brand(): string {
    return typeof this.data?.BRAND === 'string' ? this.data.BRAND : '';
  }

  get name(): string {
    return `${this.brand} ${this.model}`.trim();
  }

  get brandName(): string {
    return typeof this.data?.BRAND?.NAME === 'string' ? this.data.BRAND.NAME : '';
  }

  get brandOriginal(): string {
    return typeof this.data?.BRAND?.ORIGINAL === 'string' ? this.data.BRAND.ORIGINAL : '';
  }

  get model(): string {
    return (this.data.MODEL as string) ?? '';
  }

  get machineType() {
    return this.data.MACHINE_TYPE as MachineType;
  }

  get dealer(): string {
    return (this.data?.DEALER_REFERENCES?.SOURCE as string) ?? '';
  }

  get yearOfProduction(): number | null {
    return (this.data.YEAR_OF_PRODUCTION as number) ?? null;
  }

  get locationCountry() {
    return (this.data.LOCATION_COUNTRY as string) ?? '';
  }

  get enginePower() {
    return this.machineHp;
  }

  get engineHours(): number | null {
    return (this.data.ENGINE_HOURS as number) ?? null;
  }

  get price(): number | null {
    return (this.data.PRICE_NET_EUR as number) ?? null;
  }

  get previewImage(): TMachineMedia | undefined {
    return this.media?.[0];
  }

  get machineHp() {
    const value = this.data.ENGINE_HP;
    return value ? parseInt(value) : null;
  }

  get machineKw() {
    return (this.data.ENGINE_KW as string) ?? null;
  }

  get dealerReferenceId() {
    return (this.data.DEALER_REFERENCES.ID as string) ?? null;
  }

  get dealerReferenceSource() {
    return (this.data.DEALER_REFERENCES.SOURCE as string) ?? null;
  }

  get adScore() {
    return (this.data.AD_SCORE as number) ?? null;
  }

  get availability() {
    return (this.data.AVAILABLE as boolean) ?? false;
  }

  get balesTotalNumber() {
    return (this.data.BALES_TOTAL_NUMBER as number) ?? null;
  }

  get drumHours() {
    return (this.data.DRUM_HOURS as number) ?? null;
  }

  static fromObject({ data, media, leasingQualified = false, leasingInfo = null }: TMachine): Machine {
    return plainToClass(Machine, { data, media, leasingQualified, leasingInfo });
  }

  toObject(): TMachine {
    return classToPlain(this) as TMachine;
  }
}
