import { EFinancingLogo } from './EFinancingLogo';
import { TMachineData } from './TMachineData';
import { TMachineMedia } from './TMachineMedia';
import { TLocale } from './i18n/Locale';

export enum DealerStatus {
  Onboarded = 'Onboarded',
  FollowUp = 'Follow-up',
  Icebox = 'Icebox',
  Rejected = 'Rejected',
  ToBeContacted = 'To be contacted',
  NoStatus = 'None',
  NoRemarketing = 'No Remarketing',
  FirstCall = '1st Call',
  SecondCall = '2nd Call',
  FinalDecision = 'Final Decision',
}

type TLeasingInfo = {
  countryCode: TLocale;
  logo: EFinancingLogo;
  pmt: number;
};

export type TFullMachineData = {
  data: TMachineData;
  dealerStatus: DealerStatus;
  media?: TMachineMedia[];
  leasingQualified?: boolean;
  leasingInfo?: TLeasingInfo | null;
};

export type TMachine = Omit<TFullMachineData, 'dealerStatus'>;
