import { ArrowBack, Close } from '@mui/icons-material'
import { Typography } from '@renderer-ui-library/mui'
import classNames from 'classnames'
import { SyntheticEvent, useEffect, useMemo } from 'react'
import { useBlockGlobalScrolling } from 'utils/globalScrolling/useBlockGlobalScrolling'
import styles from './mobileOverlay.module.scss'

export type Props = {
  open: boolean
  title?: string
  hideTitle?: boolean
  titleContent?: React.ReactNode
  fullHeight?: boolean
  animationDirection?: 'left' | 'bottom'
  onCloseClick: (e: SyntheticEvent) => void
  onBackButtonClick?: (() => void) | null
  children?: React.ReactNode
}

export const MobileOverlay: React.FC<Props> = (props) => {
  const getTitle = useMemo(() => {
    if (props.titleContent) {
      return props.titleContent
    }
    if (props.title) {
      return <Typography variant='h5'>{props.title}</Typography>
    }
  }, [props.title, props.titleContent])

  const { disableGlobalScrolling, enableGlobalScrolling } =
    useBlockGlobalScrolling()

  useEffect(() => {
    if (props.open) {
      disableGlobalScrolling()
    } else {
      enableGlobalScrolling()
    }

    return () => enableGlobalScrolling()
  }, [props.open, disableGlobalScrolling, enableGlobalScrolling])

  return (
    <>
      <div
        className={classNames(styles.shim, { [styles.show]: props.open })}
        onClick={props.onCloseClick}
      />
      <div
        className={classNames(styles.overlay, {
          [styles.open]: props.open,
          [styles.fullHeight]: props.fullHeight,
          [styles.left]: props.animationDirection === 'left',
        })}
      >
        {!props.hideTitle && (
          <div className={styles.title}>
            {props.onBackButtonClick && (
              <div className={styles.back}>
                <ArrowBack
                  fontSize='inherit'
                  color='primary'
                  onClick={props.onBackButtonClick}
                />
              </div>
            )}
            {getTitle}
            <div className={styles.close}>
              <Close
                fontSize='inherit'
                color='primary'
                onClick={props.onCloseClick}
              />
            </div>
          </div>
        )}
        <div className={styles.content}>{props.children}</div>
      </div>
    </>
  )
}
