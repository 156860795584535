import { KeyboardArrowDown } from '@mui/icons-material'
import {
  ClickAwayListener,
  Paper,
  Popover,
  Typography,
} from '@renderer-ui-library/mui'
import Colors from '@renderer-ui-library/mui/base/colors.module.scss'
import {
  TLocale,
  localeNamespace,
} from '@website-shared-library/machine/i18n/Locale'
import classNames from 'classnames'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { useCallback, useState } from 'react'
import { TNavigation } from 'utils/navigation/TNavigation'
import { tracker } from 'utils/tracking/tracker'
import { AllMachinesFooter } from '../AllMachinesFooter/AllMachinesFooter'
import { AllMachinesMenu } from '../AllMachinesMenu/AllMachinesMenu'
import styles from './allMachines.module.scss'
type Props = {
  brandsNavigation: TNavigation
  allOffersUrl: string
}

export const AllMachines: React.FC<Props> = ({
  brandsNavigation,
  allOffersUrl,
}) => {
  const { t } = useTranslation(localeNamespace.common)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const close = useCallback(() => setAnchorEl(null), [])
  const handleClickEntry = useCallback(() => setAnchorEl(null), [])
  const toggleOpen = useCallback(
    (ev: React.MouseEvent<HTMLElement>) =>
      setAnchorEl((anchorEl) => {
        if (anchorEl) {
          return null
        }

        tracker.trackEvent({
          name: 'click',
          target_name: 'main_menu.all_machinery',
          is_interaction: true,
        })
        return ev.currentTarget as HTMLElement
      }),
    []
  )

  const isOpen = !!anchorEl

  return (
    <div className={styles.wrapper}>
      <Typography
        className={styles.button}
        onClick={toggleOpen}
        color={Colors.textInvertedPrimary}
        variant='body2'
        component='div'
      >
        {t(translations.headerSecondaryNavMoreLabel)}
        <div className={classNames(styles.icon, { [styles.open]: isOpen })}>
          <KeyboardArrowDown fontSize='inherit' />
        </div>
      </Typography>

      <Popover
        anchorEl={anchorEl}
        open={isOpen}
        anchorOrigin={{
          vertical: 28,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ClickAwayListener onClickAway={close}>
          <Paper elevation={4} className={styles.paper}>
            <AllMachinesMenu onClick={handleClickEntry} />
            <AllMachinesFooter
              onClick={handleClickEntry}
              brandsNavigation={brandsNavigation}
              allOffersUrl={allOffersUrl}
            />
          </Paper>
        </ClickAwayListener>
      </Popover>
    </div>
  )
}
