import { ImageLoader } from '@internal/utils/media/ImageLoader'
import {
  CircularProgress,
  Container,
  Grid,
  Typography,
} from '@renderer-ui-library/mui'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import { Machine, TMachine } from '@website-shared-library/machine'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { TSearchPayload } from '@website-shared-library/search/TSearchPayload'
import { useSearchResultsRouting } from 'blocks/SearchResultsBlock/useSearchResultsRouting'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import React, { useEffect, useMemo, useRef } from 'react'
import { getSearchResultsCount } from 'services/unauthenticatedBackendClient'
import PlaceholderImage from '../../assets/custom-404.png'
import styles from './notFoundMachineLoader.module.scss'

type TSearchParams = Omit<TSearchPayload, 'offset' | 'limit' | 'sort'>

const findResults = async (params: TSearchParams): Promise<TSearchParams> =>
  getSearchResultsCount(params).then((count) =>
    count > 0 ? Promise.resolve(params) : Promise.reject()
  )

interface Props {
  machine: TMachine
}

export const NotFoundMachineLoader: React.FC<Props> = React.memo(
  ({ machine }) => {
    const { t } = useTranslation(localeNamespace.common)
    const { gotoSearchResults } = useSearchResultsRouting()
    const { isMobile } = useMediaQuery()
    const component = useRef<{ isMounted: boolean }>({ isMounted: true })

    const machineInstance = useMemo(
      () => Machine.fromObject(machine),
      [machine]
    )

    useEffect(() => {
      Promise.all([
        findResults({
          model: machineInstance.model
            ? { name: machineInstance.model, isMapped: true }
            : undefined,
          brands: machineInstance.brand ? [machineInstance.brand] : undefined,
          machineType: machineInstance.machineType,
          filters: [],
        })
          .catch(() =>
            findResults({
              brands: [machineInstance.brand],
              machineType: machineInstance.machineType,
              filters: [],
            })
          )
          .catch(() => {
            const params: TSearchParams = {
              machineType: machineInstance.machineType,
              filters: [],
            }
            return params
          }),
        new Promise((resolve) => setTimeout(resolve, 2000)),
      ]).then(([params]) => {
        if (component.current.isMounted) {
          gotoSearchResults(params, false)
        }
      })
    }, [gotoSearchResults, machineInstance])

    return (
      <Container maxWidth='lg'>
        <Typography variant='h1'>
          {machineInstance.brand} {machineInstance.model}
        </Typography>

        <div className={styles.notAvailable}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Image
                loading='lazy'
                {...PlaceholderImage}
                alt=''
                loader={ImageLoader}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant='h2'>
                {t(translations.notFoundmachineNoLongerAvailable)}
              </Typography>
              <Typography variant='body1'>
                {t(translations.notFoundmachineMightGotSoldOrRemoved)}
              </Typography>
            </Grid>
          </Grid>
        </div>

        <Grid container>
          <Grid item xs={12} md={12}>
            <div className={styles.wrapper}>
              <Typography variant='h3' component='p'>
                {t(translations.findSimilarMachines)}
              </Typography>
              <div className={styles.progress}>
                <CircularProgress size={isMobile ? 32 : 50} />
              </div>
              <Typography variant='subtitle1' component='span'>
                {t(translations.willBeRedirectedShortly)}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    )
  }
)

NotFoundMachineLoader.displayName = 'NotFoundMachineLoader'
