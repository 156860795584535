import React from 'react'
import { PageContext } from './PageContext'
import { TPageData } from './TPageData'

interface PageProviderProps {
  pageData: TPageData | null
  pathParams: any
  children?: React.ReactNode
}

export const PageProvider: React.FC<PageProviderProps> = React.memo(
  ({ children, pageData, pathParams }) => (
    <PageContext.Provider value={{ pageData, pathParams }}>
      {children}
    </PageContext.Provider>
  )
)

PageProvider.displayName = 'PageProvider'
