import { BlockTopText } from '@renderer-ui-library/molecules'
import { Grid, Typography } from '@renderer-ui-library/mui'
import { BlockWrapper } from '@renderer-ui-library/scaffolding/BlockWrapper/BlockWrapper'
import { BlockProps } from 'blocks/BlockProps'
import classnames from 'classnames'
import { memo, useEffect, useRef, useState } from 'react'
import { INumberedFeaturesColumnsBlockData } from './INumberedFeaturesColumnsBlockData'
import styles from './numberedFeaturesColumnsBlock.module.scss'

export const NumberedFeaturesColumnsBlock: React.FC<
  BlockProps<INumberedFeaturesColumnsBlockData>
> = memo(({ data }) => {
  const columnTitleRefs = useRef({} as Record<number, HTMLSpanElement | null>)
  const columnDescriptionRefs = useRef(
    {} as Record<number, HTMLSpanElement | null>
  )

  // Workaround for multiline titles to have same height so
  // the underlines are aligned
  const [maxTitleHeight, setMaxTitleHeight] = useState<number | null>(null)
  const [maxDescriptionHeight, setMaxDescriptionHeight] =
    useState<number | null>(null)
  useEffect(() => {
    const handleResizeTitleAndDescription = () => {
      const maxTitleHeight = Math.max(
        ...Object.values(columnTitleRefs.current).map(
          (el) => el?.clientHeight || 0
        )
      )
      setMaxTitleHeight(maxTitleHeight)
      const maxDescriptionHeight = Math.max(
        ...Object.values(columnDescriptionRefs.current).map(
          (el) => el?.clientHeight || 0
        )
      )
      setMaxDescriptionHeight(maxDescriptionHeight)
    }
    window.addEventListener('resize', handleResizeTitleAndDescription)
    const timeout = setTimeout(() => {
      handleResizeTitleAndDescription()
    }, 1000)
    return () => {
      clearTimeout(timeout)
      window.removeEventListener('resize', handleResizeTitleAndDescription)
    }
  }, [columnTitleRefs])

  return (
    <BlockWrapper blockData={data}>
      {data.title && (
        <BlockTopText
          title={data.title}
          text={data.topText}
          highlight={data.highlight}
        />
      )}
      <div
        className={classnames({
          [styles.highlight]: data.highlight,
        })}
      >
        <Grid container>
          {data.columns.map((column, index) => (
            <Grid
              item
              xs={12}
              md={4}
              key={column.id}
              className={styles.gridItem}
            >
              <div>
                <div className={styles.featureNumber}>{index + 1}</div>
                <Typography
                  variant='h6'
                  textAlign='center'
                  className={styles.featureTitle}
                  style={{ height: `${maxTitleHeight}px` }}
                  ref={(el) => {
                    columnTitleRefs.current[index] = el
                    return
                  }}
                >
                  {column.title}
                </Typography>
                <Typography
                  variant='body1'
                  textAlign='center'
                  style={{ height: `${maxDescriptionHeight}px` }}
                  ref={(el) => {
                    columnDescriptionRefs.current[index] = el
                    return
                  }}
                >
                  {column.description}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </BlockWrapper>
  )
})

NumberedFeaturesColumnsBlock.displayName = 'NumberedFeaturesColumnsBlock'
