import { urlFor } from '@internal/utils/routing/urls/urlFor'
import { Text } from '@renderer-ui-library/atoms'
import { Typography } from '@renderer-ui-library/mui'
import Colors from '@renderer-ui-library/mui/base/colors.module.scss'
import {
  TLocale,
  localeNamespace,
} from '@website-shared-library/machine/i18n/Locale'
import classNames from 'classnames'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { timerCountdown } from 'utils/date/timerCountdown'
import { tracker } from 'utils/tracking/tracker'
import { HeaderContentWrapper } from '../HeaderContentWrapper/HeaderContentWrapper'
import styles from './auctionBanner.module.scss'

type Props = {
  locale: TLocale
}

export const AuctionBanner: React.FC<Props> = React.memo(({ locale }) => {
  const { t } = useTranslation(localeNamespace.common)
  const text = t(translations.globalBannerText)

  const handleClick = () => {
    tracker.trackEvent({
      name: 'click',
      target_name: 'top_banner.auction',
      is_interaction: true,
    })
  }

  const {
    days: startDays,
    hours: startHours,
    minutes: startMinutes,
  } = timerCountdown(new Date('07/02/2024 06:00 PM'))

  const {
    days: endDays,
    hours: endHours,
    minutes: endMinutes,
  } = timerCountdown(new Date('07/16/2024 06:00 PM'))

  const countdownTostartSubText = t(translations.globalBannerStartSubText, {
    days: startDays,
    hours: startHours,
    minutes: startMinutes,
  })

  const countDownToEndSubText = t(translations.globalBannerSubText, {
    days: endDays,
    hours: endHours,
    minutes: endMinutes,
  })

  if (!text) {
    return null
  }

  return (
    <a
      className={classNames(styles.outerWrapper, 'auction-banner')}
      onClick={handleClick}
      href={urlFor(locale, 'auctions')}
    >
      <HeaderContentWrapper className={styles.wrapper}>
        <div>
          <Text alignment='center' color='dark' text={text} />
        </div>
        <div className={styles.subtext}>
          <Typography color={Colors.textInvertedPrimary} variant='h6'>
            {endDays <= 0 && endHours <= 0 && endMinutes <= 0
              ? t(translations.globalBannerAboutToEndText)
              : startDays <= 0 && startHours <= 0 && startMinutes <= 0
              ? countDownToEndSubText
              : countdownTostartSubText}
          </Typography>
        </div>
      </HeaderContentWrapper>
    </a>
  )
})

AuctionBanner.displayName = 'AuctionBanner'
