import { ImageLoader } from '@internal/utils/media/ImageLoader'
import { Menu } from '@mui/icons-material'
import Logo from '@renderer-ui-library/assets/logo.svg'
import { Dropdown, LinkComponent } from '@renderer-ui-library/atoms'
import { Services } from '@renderer-ui-library/molecules'
import { Divider, Grid } from '@renderer-ui-library/mui'
import Colors from '@renderer-ui-library/mui/base/colors.module.scss'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import { Locales } from '@renderer-ui-library/scaffolding/Locales/Locales'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { FC, useCallback } from 'react'
import { TNavigation } from 'utils/navigation/TNavigation'
import { tracker } from 'utils/tracking/tracker'
import { AllMachines } from '../AllMachines/AllMachines'
import { TSearchParams } from '../SearchOverlay/SearchOverlay'
import { DynamicSearchOverlay } from '../SearchOverlay/dymanicSearchOverlay'
import styles from './mainNavigation.module.scss'

type Props = {
  headerNavigation: TNavigation
  servicesNavigation: TNavigation
  brandsNavigation: TNavigation
  locale: TLocale
  onMobileMenuClick: () => void
  searchParams: TSearchParams
  allOffersUrl: string
}

export const portalSlotDesktopId = 'navigation-portal-slot-desktop'
export const portalSlotMobileId = 'navigation-portal-slot-mobile'

export const MainNavigation: FC<Props> = ({
  locale,
  headerNavigation,
  brandsNavigation,
  onMobileMenuClick,
  servicesNavigation,
  searchParams,
  allOffersUrl,
}) => {
  const { t } = useTranslation(localeNamespace.common)
  const { isMobile } = useMediaQuery()

  const renderServices = useCallback(
    ({ close }: { close: () => void }) => {
      const handleItemClick = () => {
        close()
        tracker.trackEvent({
          name: 'click',
          target_name: 'main_menu.services',
          is_interaction: true,
        })
      }
      return (
        <Services
          navigation={servicesNavigation}
          onEntryClick={handleItemClick}
          inline
        />
      )
    },
    [servicesNavigation]
  )

  return (
    <nav className={styles.wrapper}>
      <Grid container spacing={0} alignItems='center' maxWidth={1200}>
        <Grid container item xs={5} lg={2} alignItems='center'>
          <div
            className={styles.mobileMenuButton}
            tabIndex={1}
            onClick={onMobileMenuClick}
          >
            <Menu color='inherit' fontSize='inherit' />
          </div>
          <a href='/' className={styles.logoWrapper}>
            <Image
              loading='lazy'
              src={Logo.src}
              alt='E-FARM GmbH'
              loader={ImageLoader}
              fill
            />
          </a>
        </Grid>
        <Grid
          container
          item
          xs={0}
          lg={10}
          alignItems='center'
          className={styles.desktopMenu}
        >
          {headerNavigation.entries.map((entry) => (
            <div className={styles.entry} key={entry.id}>
              <LinkComponent
                locale={locale}
                href={entry.route}
                prefetch={false}
                textColorVariant='invertedPrimary'
              >
                {entry.name}
              </LinkComponent>
            </div>
          ))}
          <div className={styles.entry}>
            <AllMachines
              brandsNavigation={brandsNavigation}
              allOffersUrl={allOffersUrl}
            />
          </div>
          <Divider
            className={styles.divider}
            color={Colors.textInvertedPrimary}
            orientation='vertical'
            variant='middle'
            flexItem
          />
          <Dropdown
            textColor={Colors.textInvertedPrimary}
            variant='secondary'
            label={t(translations.headerMainNavServicesLabel)}
            orientation='center'
            render={renderServices}
          />
          <div id={portalSlotDesktopId} />
        </Grid>
        <Grid
          container
          item
          xs={7}
          lg={1}
          alignItems='center'
          justifyContent='flex-end'
          className={styles.mobileMenu}
        >
          <Locales locale={locale} />
          {isMobile && <DynamicSearchOverlay searchParams={searchParams} />}
          <div id={portalSlotMobileId} />
        </Grid>
      </Grid>
    </nav>
  )
}
