import { displayDefinitionEntriesToStringList } from '@internal/utils/displayDefinition/displayDefinitionEntriesToStringList'
import { Machine } from '@internal/utils/machine/Machine'
import { Chip } from '@renderer-ui-library/atoms'
import { TDisplayDefinitionEntry } from '@website-shared-library/displayDefinition/entry/TDisplayDefinitionEntry'
import { TLocale } from '@website-shared-library/machine/i18n/Locale'
import { memo, useMemo } from 'react'
import styles from './machineHighlights.module.scss'

type Props = {
  machine: Machine
  locale: TLocale

  definition: TDisplayDefinitionEntry[]
}

export const MachineHighlights = memo(
  ({ machine, locale, definition }: Props) => {
    const highlights = useMemo(
      () =>
        displayDefinitionEntriesToStringList(
          locale,
          machine,
          definition,
          ', '
        ).slice(0, 4),
      [machine, definition, locale]
    )
    return (
      <div className={styles.highlightsContainer}>
        {highlights.map((highlight) => (
          <Chip key={highlight} label={highlight} />
        ))}
      </div>
    )
  }
)

MachineHighlights.displayName = 'MachineHighlights'
