import { SearchResultsSortKey } from '@internal/blocks/SearchResultsBlock/SearchResultsSortKey'
import { Locale, LocaleEnum } from '@website-shared-library/machine/i18n/Locale'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import { SystemPageKey } from 'page/SystemPageKey'
import { TRoute } from './TRoute'
import { TRouteParams } from './TRouteParams'
import { machineCategoryPageRoute } from './machineCategoryPages/machineCategoryPageRoute'
import { TRedirect } from './redirects/TRedirect'
import { getRedirectForMachine } from './redirects/getRedirectForMachine'
import { getRedirectForMachineComparePage } from './redirects/getRedirectForMachineComparePage'
import { getRedirectForUseFarmMachinery } from './redirects/getRedirectForUseFarmMachinery'
import { getRedirectToShortMachineUrl } from './redirects/getRedirectToShortMachineUrl'
import { redirect } from './redirects/redirect'
import { systemPageRoute } from './systemPages/systemPageRoute'

export type TRoutingTableEntry = Record<TLocale, TRoute<any>>

export type TSearchResultsRouteParams = TRouteParams<
  {},
  {
    headerType?: string
    baleChamber?: string
    machineType?: string
    machineSubType?: string
    brand?: string[]
    model?: string
    amount?: string
    location?: string[]
    feature?: string[]
    sort?: SearchResultsSortKey
    excludeWithoutMedia?: 'true' | 'false'
    messageType?: string
  }
>

export type TMachineDetailsRouteParams = TRouteParams<
  {
    machineType: string
    brand: string
    model: string
    machineGroupId: string
  },
  {}
>

export type TCompareMachinesRouteParams = TRouteParams<
  {
    machineGroupId0?: string
    machineGroupId1?: string
    machineGroupId2?: string
  },
  {}
>

export type TMachineCategoryPageRouteParams = TRouteParams<
  {
    machineTypeSlug: string
    brandSlug?: string
    modelSlug?: string
    brand?: string
    model?: string
  },
  {}
>

const genericCompareRoutePrefix = 'compare'
const compareRoutePrefix: Record<TLocale, string> = {
  [Locale.en]: genericCompareRoutePrefix,
  [Locale.de]: 'vergleichen',
  [Locale.fr]: 'comparer',
  [Locale.el]: 'singrisi',
  [Locale.it]: 'confronta',
  [Locale.pl]: 'porownaj',
  [Locale.ro]: 'comparare',
  [Locale.ru]: 'sravnit',
  [Locale.hu]: 'osszehasonlitas',
  [Locale.es]: 'comparar',
  [Locale.bg]: 'sravnjavane',
  [Locale.sl]: 'primerjaj',
  [Locale.sr]: 'uporedite',
  [Locale.da]: 'ammenlign',
  [Locale.cs]: 'porovnat',
  [Locale.lt]: 'palyginti',
}

export const usedFarmMachineryRoutePrefix: Record<TLocale, string> = {
  [Locale.de]: 'gebrauchte-landmaschinen',
  [Locale.en]: 'used-farm-machinery',
  [Locale.fr]: 'machines-agricoles-doccasion',
  [Locale.el]: 'metaxeirismena-georgika-mixanimata',
  [Locale.it]: 'macchine-agricole-usate',
  [Locale.pl]: 'uzywane-maszyny-rolnicze',
  [Locale.ro]: 'utilaje-agricole-second',
  [Locale.ru]: 'bu-technika',
  [Locale.hu]: 'hasznalt-mezogazdasagi-gepek',
  [Locale.es]: 'maquinaria-agricola-usada',
  [Locale.bg]: 'izpolzvani-selskostopanski-mashini',
  [Locale.sl]: 'rabljena-kmetijska-mehanizacija',
  [Locale.sr]: 'polovne-poljoprivredne-masine',
  [Locale.da]: 'brugte-landbrugsmaskiner',
  [Locale.cs]: 'pouzite-zemedelske-stroje',
  [Locale.lt]: 'naudota-zemes-ukio-technika',
}

export const routingTable: {
  systemPageRoutes: Record<SystemPageKey, TRoutingTableEntry>
  machineCategoryPageRoutes: TRoutingTableEntry
  notFoundMachineRoutes: string[]
  redirects: TRedirect[]
} = {
  // prettier-ignore
  systemPageRoutes: {
    [SystemPageKey.StartPage]: {
      [Locale.de]: systemPageRoute(SystemPageKey.StartPage, '/de/'),
      [Locale.en]: systemPageRoute(SystemPageKey.StartPage, '/en/'),
      [Locale.fr]: systemPageRoute(SystemPageKey.StartPage, '/fr/'),
      [Locale.el]: systemPageRoute(SystemPageKey.StartPage, '/el/'),
      [Locale.it]: systemPageRoute(SystemPageKey.StartPage, '/it/'),
      [Locale.pl]: systemPageRoute(SystemPageKey.StartPage, '/pl/'),
      [Locale.ro]: systemPageRoute(SystemPageKey.StartPage, '/ro/'),
      [Locale.ru]: systemPageRoute(SystemPageKey.StartPage, '/ru/'),
      [Locale.hu]: systemPageRoute(SystemPageKey.StartPage, '/hu/'),
      [Locale.es]: systemPageRoute(SystemPageKey.StartPage, '/es/'),
      [Locale.bg]: systemPageRoute(SystemPageKey.StartPage, '/bg/'),
      [Locale.sl]: systemPageRoute(SystemPageKey.StartPage, '/sl/'),
      [Locale.sr]: systemPageRoute(SystemPageKey.StartPage, '/sr/'),
      [Locale.da]: systemPageRoute(SystemPageKey.StartPage, '/da/'),
      [Locale.cs]: systemPageRoute(SystemPageKey.StartPage, '/cs/'),
      [Locale.lt]: systemPageRoute(SystemPageKey.StartPage, '/lt/'),
    },
    // this needs to be accessible, so we can get a preview of the cms content
    // and don't have to wait for another deployment / server side generation of the 404 page
    // hitting this url will return a HTTP 200 (not a 404)
    [SystemPageKey.NotFound]: {
      [Locale.de]: systemPageRoute(SystemPageKey.NotFound, '/de/_404/'),
      [Locale.en]: systemPageRoute(SystemPageKey.NotFound, '/en/_404/'),
      [Locale.fr]: systemPageRoute(SystemPageKey.NotFound, '/fr/_404/'),
      [Locale.el]: systemPageRoute(SystemPageKey.NotFound, '/el/_404/'),
      [Locale.it]: systemPageRoute(SystemPageKey.NotFound, '/it/_404/'),
      [Locale.pl]: systemPageRoute(SystemPageKey.NotFound, '/pl/_404/'),
      [Locale.ro]: systemPageRoute(SystemPageKey.NotFound, '/ro/_404/'),
      [Locale.ru]: systemPageRoute(SystemPageKey.NotFound, '/ru/_404/'),
      [Locale.hu]: systemPageRoute(SystemPageKey.NotFound, '/hu/_404/'),
      [Locale.es]: systemPageRoute(SystemPageKey.NotFound, '/es/_404/'),
      [Locale.bg]: systemPageRoute(SystemPageKey.NotFound, '/bg/_404/'),
      [Locale.sl]: systemPageRoute(SystemPageKey.NotFound, '/sl/_404/'),
      [Locale.sr]: systemPageRoute(SystemPageKey.NotFound, '/sr/_404/'),
      [Locale.da]: systemPageRoute(SystemPageKey.NotFound, '/da/_404/'),
      [Locale.cs]: systemPageRoute(SystemPageKey.NotFound, '/cs/_404/'),
      [Locale.lt]: systemPageRoute(SystemPageKey.NotFound, '/lt/_404/'),

    },
    // this needs to be accessible, so we can get a preview of the cms content
    // and don't have to wait for another deployment / server side generation of the 500 page
    // hitting this url will return a HTTP 200 (not a 500)
    [SystemPageKey.InternalServerError]: {
      [Locale.de]: systemPageRoute(SystemPageKey.InternalServerError, '/de/_500/'),
      [Locale.en]: systemPageRoute(SystemPageKey.InternalServerError, '/en/_500/'),
      [Locale.fr]: systemPageRoute(SystemPageKey.InternalServerError, '/fr/_500/'),
      [Locale.el]: systemPageRoute(SystemPageKey.InternalServerError, '/el/_500/'),
      [Locale.it]: systemPageRoute(SystemPageKey.InternalServerError, '/it/_500/'),
      [Locale.pl]: systemPageRoute(SystemPageKey.InternalServerError, '/pl/_500/'),
      [Locale.ro]: systemPageRoute(SystemPageKey.InternalServerError, '/ro/_500/'),
      [Locale.ru]: systemPageRoute(SystemPageKey.InternalServerError, '/ru/_500/'),
      [Locale.hu]: systemPageRoute(SystemPageKey.InternalServerError, '/hu/_500/'),
      [Locale.es]: systemPageRoute(SystemPageKey.InternalServerError, '/es/_500/'),
      [Locale.bg]: systemPageRoute(SystemPageKey.InternalServerError, '/bg/_500/'),
      [Locale.sl]: systemPageRoute(SystemPageKey.InternalServerError, '/sl/_500/'),
      [Locale.sr]: systemPageRoute(SystemPageKey.InternalServerError, '/sr/_500/'),
      [Locale.da]: systemPageRoute(SystemPageKey.InternalServerError, '/da/_500/'),
      [Locale.cs]: systemPageRoute(SystemPageKey.InternalServerError, '/cs/_500/'),
      [Locale.lt]: systemPageRoute(SystemPageKey.InternalServerError, '/lt/_500/'),
    },
    [SystemPageKey.PDP]: {
      [Locale.de]: systemPageRoute<TMachineDetailsRouteParams>(SystemPageKey.PDP, `/de/${usedFarmMachineryRoutePrefix[Locale.de]}/:machineType/:brand/:model/:machineGroupId([a-z0-9]{7})/`),
      [Locale.en]: systemPageRoute<TMachineDetailsRouteParams>(SystemPageKey.PDP, `/en/${usedFarmMachineryRoutePrefix[Locale.en]}/:machineType/:brand/:model/:machineGroupId([a-z0-9]{7})/`),
      [Locale.fr]: systemPageRoute<TMachineDetailsRouteParams>(SystemPageKey.PDP, `/fr/${usedFarmMachineryRoutePrefix[Locale.fr]}/:machineType/:brand/:model/:machineGroupId([a-z0-9]{7})/`),
      [Locale.it]: systemPageRoute<TMachineDetailsRouteParams>(SystemPageKey.PDP, `/it/${usedFarmMachineryRoutePrefix[Locale.it]}/:machineType/:brand/:model/:machineGroupId([a-z0-9]{7})/`),
      [Locale.pl]: systemPageRoute<TMachineDetailsRouteParams>(SystemPageKey.PDP, `/pl/${usedFarmMachineryRoutePrefix[Locale.pl]}/:machineType/:brand/:model/:machineGroupId([a-z0-9]{7})/`),
      [Locale.el]: systemPageRoute<TMachineDetailsRouteParams>(SystemPageKey.PDP, `/el/${usedFarmMachineryRoutePrefix[Locale.el]}/:machineType/:brand/:model/:machineGroupId([a-z0-9]{7})/`),
      [Locale.ro]: systemPageRoute<TMachineDetailsRouteParams>(SystemPageKey.PDP, `/ro/${usedFarmMachineryRoutePrefix[Locale.ro]}/:machineType/:brand/:model/:machineGroupId([a-z0-9]{7})/`),
      [Locale.ru]: systemPageRoute<TMachineDetailsRouteParams>(SystemPageKey.PDP, `/ru/${usedFarmMachineryRoutePrefix[Locale.ru]}/:machineType/:brand/:model/:machineGroupId([a-z0-9]{7})/`),
      [Locale.hu]: systemPageRoute<TMachineDetailsRouteParams>(SystemPageKey.PDP, `/hu/${usedFarmMachineryRoutePrefix[Locale.hu]}/:machineType/:brand/:model/:machineGroupId([a-z0-9]{7})/`),
      [Locale.es]: systemPageRoute<TMachineDetailsRouteParams>(SystemPageKey.PDP, `/es/${usedFarmMachineryRoutePrefix[Locale.es]}/:machineType/:brand/:model/:machineGroupId([a-z0-9]{7})/`),
      [Locale.bg]: systemPageRoute<TMachineDetailsRouteParams>(SystemPageKey.PDP, `/bg/${usedFarmMachineryRoutePrefix[Locale.bg]}/:machineType/:brand/:model/:machineGroupId([a-z0-9]{7})/`),
      [Locale.sl]: systemPageRoute<TMachineDetailsRouteParams>(SystemPageKey.PDP, `/sl/${usedFarmMachineryRoutePrefix[Locale.sl]}/:machineType/:brand/:model/:machineGroupId([a-z0-9]{7})/`),
      [Locale.sr]: systemPageRoute<TMachineDetailsRouteParams>(SystemPageKey.PDP, `/sr/${usedFarmMachineryRoutePrefix[Locale.sr]}/:machineType/:brand/:model/:machineGroupId([a-z0-9]{7})/`),
      [Locale.da]: systemPageRoute<TMachineDetailsRouteParams>(SystemPageKey.PDP, `/da/${usedFarmMachineryRoutePrefix[Locale.da]}/:machineType/:brand/:model/:machineGroupId([a-z0-9]{7})/`),
      [Locale.cs]: systemPageRoute<TMachineDetailsRouteParams>(SystemPageKey.PDP, `/cs/${usedFarmMachineryRoutePrefix[Locale.cs]}/:machineType/:brand/:model/:machineGroupId([a-z0-9]{7})/`),
      [Locale.lt]: systemPageRoute<TMachineDetailsRouteParams>(SystemPageKey.PDP, `/lt/${usedFarmMachineryRoutePrefix[Locale.lt]}/:machineType/:brand/:model/:machineGroupId([a-z0-9]{7})/`),

    },
    [SystemPageKey.SearchResults]: {
      [Locale.de]: systemPageRoute<TSearchResultsRouteParams>(SystemPageKey.SearchResults, `/de/${usedFarmMachineryRoutePrefix[Locale.de]}/`, 'queryparams-required'),
      [Locale.en]: systemPageRoute<TSearchResultsRouteParams>(SystemPageKey.SearchResults, `/en/${usedFarmMachineryRoutePrefix[Locale.en]}/`, 'queryparams-required'),
      [Locale.fr]: systemPageRoute<TSearchResultsRouteParams>(SystemPageKey.SearchResults, `/fr/${usedFarmMachineryRoutePrefix[Locale.fr]}/`, 'queryparams-required'),
      [Locale.el]: systemPageRoute<TSearchResultsRouteParams>(SystemPageKey.SearchResults, `/el/${usedFarmMachineryRoutePrefix[Locale.el]}/`, 'queryparams-required'),
      [Locale.it]: systemPageRoute<TSearchResultsRouteParams>(SystemPageKey.SearchResults, `/it/${usedFarmMachineryRoutePrefix[Locale.it]}/`, 'queryparams-required'),
      [Locale.pl]: systemPageRoute<TSearchResultsRouteParams>(SystemPageKey.SearchResults, `/pl/${usedFarmMachineryRoutePrefix[Locale.pl]}/`, 'queryparams-required'),
      [Locale.ro]: systemPageRoute<TSearchResultsRouteParams>(SystemPageKey.SearchResults, `/ro/${usedFarmMachineryRoutePrefix[Locale.ro]}/`, 'queryparams-required'),
      [Locale.ru]: systemPageRoute<TSearchResultsRouteParams>(SystemPageKey.SearchResults, `/ru/${usedFarmMachineryRoutePrefix[Locale.ru]}/`, 'queryparams-required'),
      [Locale.hu]: systemPageRoute<TSearchResultsRouteParams>(SystemPageKey.SearchResults, `/hu/${usedFarmMachineryRoutePrefix[Locale.hu]}/`, 'queryparams-required'),
      [Locale.es]: systemPageRoute<TSearchResultsRouteParams>(SystemPageKey.SearchResults, `/es/${usedFarmMachineryRoutePrefix[Locale.es]}/`, 'queryparams-required'),
      [Locale.bg]: systemPageRoute<TSearchResultsRouteParams>(SystemPageKey.SearchResults, `/bg/${usedFarmMachineryRoutePrefix[Locale.bg]}/`, 'queryparams-required'),
      [Locale.sl]: systemPageRoute<TSearchResultsRouteParams>(SystemPageKey.SearchResults, `/sl/${usedFarmMachineryRoutePrefix[Locale.sl]}/`, 'queryparams-required'),
      [Locale.sr]: systemPageRoute<TSearchResultsRouteParams>(SystemPageKey.SearchResults, `/sr/${usedFarmMachineryRoutePrefix[Locale.sr]}/`, 'queryparams-required'),
      [Locale.da]: systemPageRoute<TSearchResultsRouteParams>(SystemPageKey.SearchResults, `/da/${usedFarmMachineryRoutePrefix[Locale.da]}/`, 'queryparams-required'),
      [Locale.cs]: systemPageRoute<TSearchResultsRouteParams>(SystemPageKey.SearchResults, `/cs/${usedFarmMachineryRoutePrefix[Locale.cs]}/`, 'queryparams-required'),
      [Locale.lt]: systemPageRoute<TSearchResultsRouteParams>(SystemPageKey.SearchResults, `/lt/${usedFarmMachineryRoutePrefix[Locale.lt]}/`, 'queryparams-required'),
    },
    [SystemPageKey.CompareMachines]: {
      [Locale.de]: systemPageRoute<TCompareMachinesRouteParams>(SystemPageKey.CompareMachines, `/de/${compareRoutePrefix['de']}/:machineGroupId0([a-z0-9]{7})?/:machineGroupId1([a-z0-9]{7})?/:machineGroupId2([a-z0-9]{7})?/`),
      [Locale.en]: systemPageRoute<TCompareMachinesRouteParams>(SystemPageKey.CompareMachines, `/en/${compareRoutePrefix['en']}/:machineGroupId0([a-z0-9]{7})?/:machineGroupId1([a-z0-9]{7})?/:machineGroupId2([a-z0-9]{7})?/`),
      [Locale.fr]: systemPageRoute<TCompareMachinesRouteParams>(SystemPageKey.CompareMachines, `/fr/${compareRoutePrefix['fr']}/:machineGroupId0([a-z0-9]{7})?/:machineGroupId1([a-z0-9]{7})?/:machineGroupId2([a-z0-9]{7})?/`),
      [Locale.el]: systemPageRoute<TCompareMachinesRouteParams>(SystemPageKey.CompareMachines, `/el/${compareRoutePrefix['el']}/:machineGroupId0([a-z0-9]{7})?/:machineGroupId1([a-z0-9]{7})?/:machineGroupId2([a-z0-9]{7})?/`),
      [Locale.it]: systemPageRoute<TCompareMachinesRouteParams>(SystemPageKey.CompareMachines, `/it/${compareRoutePrefix['it']}/:machineGroupId0([a-z0-9]{7})?/:machineGroupId1([a-z0-9]{7})?/:machineGroupId2([a-z0-9]{7})?/`),
      [Locale.pl]: systemPageRoute<TCompareMachinesRouteParams>(SystemPageKey.CompareMachines, `/pl/${compareRoutePrefix['pl']}/:machineGroupId0([a-z0-9]{7})?/:machineGroupId1([a-z0-9]{7})?/:machineGroupId2([a-z0-9]{7})?/`),
      [Locale.ro]: systemPageRoute<TCompareMachinesRouteParams>(SystemPageKey.CompareMachines, `/ro/${compareRoutePrefix['ro']}/:machineGroupId0([a-z0-9]{7})?/:machineGroupId1([a-z0-9]{7})?/:machineGroupId2([a-z0-9]{7})?/`),
      [Locale.ru]: systemPageRoute<TCompareMachinesRouteParams>(SystemPageKey.CompareMachines, `/ru/${compareRoutePrefix['ru']}/:machineGroupId0([a-z0-9]{7})?/:machineGroupId1([a-z0-9]{7})?/:machineGroupId2([a-z0-9]{7})?/`),
      [Locale.hu]: systemPageRoute<TCompareMachinesRouteParams>(SystemPageKey.CompareMachines, `/hu/${compareRoutePrefix['hu']}/:machineGroupId0([a-z0-9]{7})?/:machineGroupId1([a-z0-9]{7})?/:machineGroupId2([a-z0-9]{7})?/`),
      [Locale.es]: systemPageRoute<TCompareMachinesRouteParams>(SystemPageKey.CompareMachines, `/es/${compareRoutePrefix['es']}/:machineGroupId0([a-z0-9]{7})?/:machineGroupId1([a-z0-9]{7})?/:machineGroupId2([a-z0-9]{7})?/`),
      [Locale.bg]: systemPageRoute<TCompareMachinesRouteParams>(SystemPageKey.CompareMachines, `/bg/${compareRoutePrefix['bg']}/:machineGroupId0([a-z0-9]{7})?/:machineGroupId1([a-z0-9]{7})?/:machineGroupId2([a-z0-9]{7})?/`),
      [Locale.sl]: systemPageRoute<TCompareMachinesRouteParams>(SystemPageKey.CompareMachines, `/sl/${compareRoutePrefix['sl']}/:machineGroupId0([a-z0-9]{7})?/:machineGroupId1([a-z0-9]{7})?/:machineGroupId2([a-z0-9]{7})?/`),
      [Locale.sr]: systemPageRoute<TCompareMachinesRouteParams>(SystemPageKey.CompareMachines, `/sr/${compareRoutePrefix['sr']}/:machineGroupId0([a-z0-9]{7})?/:machineGroupId1([a-z0-9]{7})?/:machineGroupId2([a-z0-9]{7})?/`),
      [Locale.da]: systemPageRoute<TCompareMachinesRouteParams>(SystemPageKey.CompareMachines, `/da/${compareRoutePrefix['da']}/:machineGroupId0([a-z0-9]{7})?/:machineGroupId1([a-z0-9]{7})?/:machineGroupId2([a-z0-9]{7})?/`),
      [Locale.cs]: systemPageRoute<TCompareMachinesRouteParams>(SystemPageKey.CompareMachines, `/cd/${compareRoutePrefix['cs']}/:machineGroupId0([a-z0-9]{7})?/:machineGroupId1([a-z0-9]{7})?/:machineGroupId2([a-z0-9]{7})?/`),
      [Locale.lt]: systemPageRoute<TCompareMachinesRouteParams>(SystemPageKey.CompareMachines, `/lt/${compareRoutePrefix['lt']}/:machineGroupId0([a-z0-9]{7})?/:machineGroupId1([a-z0-9]{7})?/:machineGroupId2([a-z0-9]{7})?/`),
    },
  },

  notFoundMachineRoutes: [
    '/:locale/(.*)/:machineType/:brand/:model/:machineGroupId([a-zA-Z0-9]{7})/',
    '/:locale/:machineGroupId([a-zA-Z0-9]{7})/',
  ],

  machineCategoryPageRoutes: {
    [Locale.de]: machineCategoryPageRoute(
      `/de/${
        usedFarmMachineryRoutePrefix[Locale.de]
      }/:machineTypeSlug/:brandSlug?/:modelSlug?/`
    ),
    [Locale.en]: machineCategoryPageRoute(
      `/en/${
        usedFarmMachineryRoutePrefix[Locale.en]
      }/:machineTypeSlug/:brandSlug?/:modelSlug?/`
    ),
    [Locale.fr]: machineCategoryPageRoute(
      `/fr/${
        usedFarmMachineryRoutePrefix[Locale.fr]
      }/:machineTypeSlug/:brandSlug?/:modelSlug?/`
    ),
    [Locale.el]: machineCategoryPageRoute(
      `/el/${
        usedFarmMachineryRoutePrefix[Locale.el]
      }/:machineTypeSlug/:brandSlug?/:modelSlug?/`
    ),
    [Locale.it]: machineCategoryPageRoute(
      `/it/${
        usedFarmMachineryRoutePrefix[Locale.it]
      }/:machineTypeSlug/:brandSlug?/:modelSlug?/`
    ),
    [Locale.pl]: machineCategoryPageRoute(
      `/pl/${
        usedFarmMachineryRoutePrefix[Locale.pl]
      }/:machineTypeSlug/:brandSlug?/:modelSlug?/`
    ),
    [Locale.ro]: machineCategoryPageRoute(
      `/ro/${
        usedFarmMachineryRoutePrefix[Locale.ro]
      }/:machineTypeSlug/:brandSlug?/:modelSlug?/`
    ),
    [Locale.ru]: machineCategoryPageRoute(
      `/ru/${
        usedFarmMachineryRoutePrefix[Locale.ru]
      }/:machineTypeSlug/:brandSlug?/:modelSlug?/`
    ),
    [Locale.hu]: machineCategoryPageRoute(
      `/hu/${
        usedFarmMachineryRoutePrefix[Locale.hu]
      }/:machineTypeSlug/:brandSlug?/:modelSlug?/`
    ),
    [Locale.es]: machineCategoryPageRoute(
      `/es/${
        usedFarmMachineryRoutePrefix[Locale.es]
      }/:machineTypeSlug/:brandSlug?/:modelSlug?/`
    ),
    [Locale.bg]: machineCategoryPageRoute(
      `/bg/${
        usedFarmMachineryRoutePrefix[Locale.bg]
      }/:machineTypeSlug/:brandSlug?/:modelSlug?/`
    ),
    [Locale.sl]: machineCategoryPageRoute(
      `/sl/${
        usedFarmMachineryRoutePrefix[Locale.sl]
      }/:machineTypeSlug/:brandSlug?/:modelSlug?/`
    ),
    [Locale.sr]: machineCategoryPageRoute(
      `/sr/${
        usedFarmMachineryRoutePrefix[Locale.sr]
      }/:machineTypeSlug/:brandSlug?/:modelSlug?/`
    ),
    [Locale.da]: machineCategoryPageRoute(
      `/da/${
        usedFarmMachineryRoutePrefix[Locale.da]
      }/:machineTypeSlug/:brandSlug?/:modelSlug?/`
    ),
    [Locale.cs]: machineCategoryPageRoute(
      `/cs/${
        usedFarmMachineryRoutePrefix[Locale.cs]
      }/:machineTypeSlug/:brandSlug?/:modelSlug?/`
    ),
    [Locale.lt]: machineCategoryPageRoute(
      `/lt/${
        usedFarmMachineryRoutePrefix[Locale.lt]
      }/:machineTypeSlug/:brandSlug?/:modelSlug?/`
    ),
  },

  // prettier-ignore
  redirects: [
   ...Object.values(LocaleEnum).map(locale => redirect(`/${locale}/${usedFarmMachineryRoutePrefix[locale]}/`, getRedirectForUseFarmMachinery)),

    // redirect non pure lowercase machineGroupId
    redirect<{ machineGroupId: string }>('/:locale/(.*)/:machineType/:brand/:model/:machineGroupId([a-zA-Z0-9]{7})/', getRedirectForMachine),
    // redirect short machine url
    redirect<{ machineGroupId: string }>('/:locale/:machineGroupId([a-zA-Z0-9]{7})/', getRedirectForMachine),
    // support legacy short url for machine, e.g. https://e-farm.com/en/search/?sSearch=7XLP16K
    redirect<{ }, {sSearch?: string}>('/:locale/search/', getRedirectToShortMachineUrl),

    // machine compare page
    redirect<{ machineGroupId0?: string; machineGroupId1?: string; machineGroupId2?: string; }>(`/de/${genericCompareRoutePrefix}/:machineGroupId0([a-zA-Z0-9]{7})?/:machineGroupId1([a-zA-Z0-9]{7})?/:machineGroupId2([a-zA-Z0-9]{7})?/`, getRedirectForMachineComparePage),
    redirect<{ machineGroupId0?: string; machineGroupId1?: string; machineGroupId2?: string; }>(`/fr/${genericCompareRoutePrefix}/:machineGroupId0([a-zA-Z0-9]{7})?/:machineGroupId1([a-zA-Z0-9]{7})?/:machineGroupId2([a-zA-Z0-9]{7})?/`, getRedirectForMachineComparePage),
    redirect<{ machineGroupId0?: string; machineGroupId1?: string; machineGroupId2?: string; }>(`/el/${genericCompareRoutePrefix}/:machineGroupId0([a-zA-Z0-9]{7})?/:machineGroupId1([a-zA-Z0-9]{7})?/:machineGroupId2([a-zA-Z0-9]{7})?/`, getRedirectForMachineComparePage),
    redirect<{ machineGroupId0?: string; machineGroupId1?: string; machineGroupId2?: string; }>(`/it/${genericCompareRoutePrefix}/:machineGroupId0([a-zA-Z0-9]{7})?/:machineGroupId1([a-zA-Z0-9]{7})?/:machineGroupId2([a-zA-Z0-9]{7})?/`, getRedirectForMachineComparePage),
    redirect<{ machineGroupId0?: string; machineGroupId1?: string; machineGroupId2?: string; }>(`/pl/${genericCompareRoutePrefix}/:machineGroupId0([a-zA-Z0-9]{7})?/:machineGroupId1([a-zA-Z0-9]{7})?/:machineGroupId2([a-zA-Z0-9]{7})?/`, getRedirectForMachineComparePage),
    redirect<{ machineGroupId0?: string; machineGroupId1?: string; machineGroupId2?: string; }>(`/ro/${genericCompareRoutePrefix}/:machineGroupId0([a-zA-Z0-9]{7})?/:machineGroupId1([a-zA-Z0-9]{7})?/:machineGroupId2([a-zA-Z0-9]{7})?/`, getRedirectForMachineComparePage),
    redirect<{ machineGroupId0?: string; machineGroupId1?: string; machineGroupId2?: string; }>(`/ru/${genericCompareRoutePrefix}/:machineGroupId0([a-zA-Z0-9]{7})?/:machineGroupId1([a-zA-Z0-9]{7})?/:machineGroupId2([a-zA-Z0-9]{7})?/`, getRedirectForMachineComparePage),
    redirect<{ machineGroupId0?: string; machineGroupId1?: string; machineGroupId2?: string; }>(`/hu/${genericCompareRoutePrefix}/:machineGroupId0([a-zA-Z0-9]{7})?/:machineGroupId1([a-zA-Z0-9]{7})?/:machineGroupId2([a-zA-Z0-9]{7})?/`, getRedirectForMachineComparePage),
    redirect<{ machineGroupId0?: string; machineGroupId1?: string; machineGroupId2?: string; }>(`/es/${genericCompareRoutePrefix}/:machineGroupId0([a-zA-Z0-9]{7})?/:machineGroupId1([a-zA-Z0-9]{7})?/:machineGroupId2([a-zA-Z0-9]{7})?/`, getRedirectForMachineComparePage),
    redirect<{ machineGroupId0?: string; machineGroupId1?: string; machineGroupId2?: string; }>(`/bg/${genericCompareRoutePrefix}/:machineGroupId0([a-zA-Z0-9]{7})?/:machineGroupId1([a-zA-Z0-9]{7})?/:machineGroupId2([a-zA-Z0-9]{7})?/`, getRedirectForMachineComparePage),
    redirect<{ machineGroupId0?: string; machineGroupId1?: string; machineGroupId2?: string; }>(`/sl/${genericCompareRoutePrefix}/:machineGroupId0([a-zA-Z0-9]{7})?/:machineGroupId1([a-zA-Z0-9]{7})?/:machineGroupId2([a-zA-Z0-9]{7})?/`, getRedirectForMachineComparePage),
    redirect<{ machineGroupId0?: string; machineGroupId1?: string; machineGroupId2?: string; }>(`/sr/${genericCompareRoutePrefix}/:machineGroupId0([a-zA-Z0-9]{7})?/:machineGroupId1([a-zA-Z0-9]{7})?/:machineGroupId2([a-zA-Z0-9]{7})?/`, getRedirectForMachineComparePage),
    redirect<{ machineGroupId0?: string; machineGroupId1?: string; machineGroupId2?: string; }>(`/da/${genericCompareRoutePrefix}/:machineGroupId0([a-zA-Z0-9]{7})?/:machineGroupId1([a-zA-Z0-9]{7})?/:machineGroupId2([a-zA-Z0-9]{7})?/`, getRedirectForMachineComparePage),
    redirect<{ machineGroupId0?: string; machineGroupId1?: string; machineGroupId2?: string; }>(`/cs/${genericCompareRoutePrefix}/:machineGroupId0([a-zA-Z0-9]{7})?/:machineGroupId1([a-zA-Z0-9]{7})?/:machineGroupId2([a-zA-Z0-9]{7})?/`, getRedirectForMachineComparePage),
    redirect<{ machineGroupId0?: string; machineGroupId1?: string; machineGroupId2?: string; }>(`/lt/${genericCompareRoutePrefix}/:machineGroupId0([a-zA-Z0-9]{7})?/:machineGroupId1([a-zA-Z0-9]{7})?/:machineGroupId2([a-zA-Z0-9]{7})?/`, getRedirectForMachineComparePage),
  ],
}
