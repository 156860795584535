import { Locale, LocaleEnum } from '@website-shared-library/machine/i18n/Locale'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import bg from '../../public/locales/bg/common.json'
import cs from '../../public/locales/cs/common.json'
import da from '../../public/locales/da/common.json'
import de from '../../public/locales/de/common.json'
import el from '../../public/locales/el/common.json'
import en from '../../public/locales/en/common.json'
import es from '../../public/locales/es/common.json'
import fr from '../../public/locales/fr/common.json'
import hu from '../../public/locales/hu/common.json'
import it from '../../public/locales/it/common.json'
import lt from '../../public/locales/lt/common.json'
import pl from '../../public/locales/pl/common.json'
import ro from '../../public/locales/ro/common.json'
import ru from '../../public/locales/ru/common.json'
import sl from '../../public/locales/sl/common.json'
import sr from '../../public/locales/sr/common.json'

const translations: Record<TLocale, { [key: string]: string }> = {
  // forced typing here will stop TS from complaining while we are adding new translations
  [Locale.de]: de,
  [Locale.en]: en,
  [Locale.fr]: fr,
  [Locale.el]: el,
  [Locale.it]: it,
  [Locale.pl]: pl,
  [Locale.ro]: ro,
  [Locale.ru]: ru,
  [Locale.hu]: hu,
  [Locale.es]: es,
  [Locale.bg]: bg,
  [Locale.sl]: sl,
  [Locale.sr]: sr,
  [Locale.da]: da,
  [Locale.cs]: cs,
  [Locale.lt]: lt,
}

export const getServerSideTranslations = (
  locale: TLocale,
  key: string,
  options?: { count?: number; [key: string]: number | string | undefined }
) => {
  const count = options?.count ?? 0
  const suffix = count === 1 ? '_one' : '_other'

  let translation: string =
    translations[locale][`${key}${suffix}`] || translations[locale][key] || ''

  for (let key in options) {
    const value = options[key]
    if (key === 'count' || value === undefined) {
      continue
    }

    translation = translation.replace(`{{${key}}}`, `${value}`)
  }

  return translation
}

export const getAllTranslationsForSingleKey = (key: string) =>
  Object.keys(translations)
    .map((locale: string) => ({
      translation: translations[locale as TLocale][key],
      locale: locale as LocaleEnum,
    }))
    .reduce<Record<TLocale, string>>(
      (acc, value) => ({
        ...acc,
        [value.locale as TLocale]: value.translation,
      }),
      {} as Record<TLocale, string>
    )
