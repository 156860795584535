import { Translation } from '../../machine/i18n/Translation';
import { MachineType } from '../../machine/MachineType';
export const machineMapping = (translations: Translation) => {
  const mapping: Record<MachineType, string> = {
    [MachineType.ANIMAL_HUSBANDRY_EQUIPMENT]: translations.machineTypeAnimalHusbandryEquipment,
    [MachineType.ATTACHMENT]: translations.machineTypeAttachment,
    [MachineType.BALE_WRAPPER]: translations.machineTypeBaleWrapper,
    [MachineType.BACK_HOE_LOADER]: translations.machineTypeBackHoeLoader,
    [MachineType.BALER]: translations.machineTypeBaler,
    [MachineType.BEET_HARVESTING_EQUIPMENT]: translations.machineTypeBeetHarvestingEquipment,
    [MachineType.CHASER_BIN]: translations.machineTypeChaserBin,
    [MachineType.COMBINE_HARVESTER]: translations.machineTypeCombineHarvester,
    [MachineType.COMPLEMENTARY_SEEDER]: translations.machineTypeComplementarySeeder,
    [MachineType.CONCRETE_MIXER]: translations.machineTypeConcreteMixer,
    [MachineType.CONSTRUCTION_MACHINERY_OTHER]: translations.machineTypeConstructionMachineryOther,
    [MachineType.CULTIVATOR]: translations.machineTypeCultivator,
    [MachineType.CUTTERS_FLAIL_MOWER_OTHER]: translations.machineTypeCuttersFlailMowerOther,
    [MachineType.DIRECT_SOWING_MACHINE]: translations.machineTypeDirectSowingMachine,
    [MachineType.DISC_HARROW_AND_COMPACT_DISC]: translations.machineTypeDiscHarrowAndCompactDisc,
    [MachineType.DRILL]: translations.machineTypeDrill,
    [MachineType.DRILLING_MACHINE_COMBINATION]: translations.machineTypeDrillingMachineCombination,
    [MachineType.DUMPER]: translations.machineTypeDumper,
    [MachineType.EXCAVATOR]: translations.machineTypeExcavator,
    [MachineType.EXCAVATOR_CRAWLER]: translations.machineTypeExcavatorCrawler,
    [MachineType.EXCAVATOR_MINI]: translations.machineTypeExcavatorMini,
    [MachineType.EXCAVATORS_OTHER]: translations.machineTypeExcavatorsOther,
    [MachineType.EXCAVATOR_WHEELED]: translations.machineTypeExcavatorWheeled,
    [MachineType.FARMSHOP]: translations.machineTypeFarmshop,
    [MachineType.FEEDER_OTHER]: translations.machineTypeFeederOther,
    [MachineType.FERTILISER_SPREADER]: translations.machineTypeFertiliserSpreader,
    [MachineType.FORAGE_HARVESTER_SELF_PROPELLED]: translations.machineTypeForageHarvesterSelfPropelled,
    [MachineType.FORAGE_HARVESTER_TRAILED_MOUNTED]: translations.machineTypeForageHarvesterTrailedMounted,
    [MachineType.FORAGE_TRANSPORT]: translations.machineTypeForageTransport,
    [MachineType.FORAGING_EQUIPMENT_OTHER]: translations.machineTypeForagingEquipmentOther,
    [MachineType.FORESTRY_EQUIPMENT]: translations.machineTypeForestryEquipment,
    [MachineType.FORESTRY_HARVESTER]: translations.machineTypeForestryHarvester,
    [MachineType.FORESTRY_MOWER]: translations.machineTypeForestryMower,
    [MachineType.FORESTRY_TRAILER]: translations.machineTypeForestryTrailer,
    [MachineType.FORKLIFT]: translations.machineTypeForklift,
    [MachineType.FORWARDER]: translations.machineTypeForwarder,
    [MachineType.GPS_SYSTEMS_PRECISION_FARMING]: translations.machineTypeGpsSystemsPrecisionFarming,
    [MachineType.GRAIN_STORAGE_HANDLING]: translations.machineTypeGrainStorageHandling,
    [MachineType.GRAPE_HARVESTER]: translations.machineTypeGrapeHarvester,
    [MachineType.HAULM_TOPPER]: translations.machineTypeHaulmTopper,
    [MachineType.HEADER]: translations.machineTypeHeader,
    [MachineType.HEDGE_MOWER]: translations.machineTypeHedgeMower,
    [MachineType.HOP_EQUIPMENT]: translations.machineTypeHopEquipment,
    [MachineType.IRRIGATION]: translations.machineTypeIrrigation,
    [MachineType.LAWN_MOWER]: translations.machineTypeLawnMower,
    [MachineType.MANURE_COMPOST_SPREADER]: translations.machineTypeManureCompostSpreader,
    [MachineType.MECHANICAL_WEED_CONTROL]: translations.machineTypeMechanicalWeedControl,
    [MachineType.MINI_LOADER]: translations.machineTypeMiniLoader,
    [MachineType.MILKING_EQUIPMENT]: translations.machineTypeMilkingEquipment,
    [MachineType.MIXER_FEEDERS]: translations.machineTypeMixerFeeders,
    [MachineType.MOTOR_VEHICLE]: translations.machineTypeMotorVehicle,
    [MachineType.MOWER]: translations.machineTypeMower,
    [MachineType.MUNICIPAL_WINTER_SERVICE_EQUIPMENT]: translations.machineTypeMunicipalWinterServiceEquipment,
    [MachineType.ORCHARD_AND_VINEYARD_EQUIPMENT_OTHER]: translations.machineTypeOrchardAndVineyardEquipmentOther,
    [MachineType.PARTS]: translations.machineTypeParts,
    [MachineType.PLANTER]: translations.machineTypePlanter,
    [MachineType.PLOUGH]: translations.machineTypePlough,
    [MachineType.POTATO_EQUIPMENT]: translations.machineTypePotatoEquipment,
    [MachineType.POTATO_HARVESTER]: translations.machineTypePotatoHarvester,
    [MachineType.POWER_HARROW]: translations.machineTypePowerHarrow,
    [MachineType.PRECISION_DRILL]: translations.machineTypePrecisionDrill,
    [MachineType.PRESSES_AND_ROLLER]: translations.machineTypePressesAndRoller,
    [MachineType.PUSH_OFF_TRAILER]: translations.machineTypePushOffTrailer,
    [MachineType.ROAD_ROLLER]: translations.machineTypeRoadRoller,
    [MachineType.ROTARY_RAKE_WINDROWER]: translations.machineTypeRotaryRakeWindrower,
    [MachineType.SAWS_AND_SPLITTERS]: translations.machineTypeSawsAndSplitters,
    [MachineType.SEEDBED_COMBINATION]: translations.machineTypeSeedbedCombination,
    [MachineType.SELF_PROPELLED_LIQUID_MANURE_SPREADER]: translations.machineTypeSelfPropelledLiquidManureSpreader,
    [MachineType.SELF_PROPELLED_PLATFORM]: translations.machineTypeSelfPropelledPlatform,
    [MachineType.SILAGE_CUTTERS_FEEDER]: translations.machineTypeSilageCuttersFeeder,
    [MachineType.SKID_STEER_LOADER]: translations.machineTypeSkidSteerLoader,
    [MachineType.SLURRY_ATTACHMENT]: translations.machineTypeSlurryAttachment,
    [MachineType.SLURRY_EQUIPMENT_OTHER]: translations.machineTypeSlurryEquipmentOther,
    [MachineType.SLURRY_LOGISTIC]: translations.machineTypeSlurryLogistic,
    [MachineType.SLURRY_TANKER]: translations.machineTypeSlurryTanker,
    [MachineType.SOWING_MACHINE_OTHER]: translations.machineTypeSowingMachineOther,
    [MachineType.SPRAYER]: translations.machineTypeSprayer,
    [MachineType.STONE_CRUSHER]: translations.machineTypeStoneCrusher,
    [MachineType.TANKER]: translations.machineTypeTanker,
    [MachineType.TEDDER]: translations.machineTypeTedder,
    [MachineType.TELE_WHEEL_LOADER]: translations.machineTypeTeleWheelLoader,
    [MachineType.TELEHANDLER]: translations.machineTypeTelehandler,
    [MachineType.TILLAGE_OTHER]: translations.machineTypeTillageOther,
    [MachineType.TIPPER]: translations.machineTypeTipper,
    [MachineType.TOPPER]: translations.machineTypeTopper,
    [MachineType.TRACTOR]: translations.machineTypeTractor,
    [MachineType.TRAILER]: translations.machineTypeTrailer,
    [MachineType.VEGETABLE_CLEANING_PROCESSING_EQUIPMENT]: translations.machineTypeVegetableCleaningProcessingEquipment,
    [MachineType.VEGETABLE_HARVESTER]: translations.machineTypeVegetableHarvester,
    [MachineType.VERGE_MOWER]: translations.machineTypeVergeMower,
    [MachineType.VIBRATING_PLATE]: translations.machineTypeVibratingPlate,
    [MachineType.WHEEL_AND_TRACK]: translations.machineTypeWheelAndTrack,
    [MachineType.WHEEL_LOADER]: translations.machineTypeWheelLoader,
    [MachineType.WOOD_CHIPPER_GRINDER]: translations.machineTypeWoodChipperGrinder,
    [MachineType.DRAINAGE]: translations.machineTypeDrainage,
    [MachineType.HORIZONTAL_AXIS_SHREDDER]: translations.machineTypeHorizontalAxisShredder,
    [MachineType.SPREADING_SPRAYING_EQUIPMENT_OTHER]: translations.machineTypeSpreadingSprayingEquipmentOther,
    [MachineType.VERTICAL_AXIS_SHREDDER]: translations.machineTypeVerticalAxisShredder,
    [MachineType.METRAC]: translations.machineTypeMetrac,
    [MachineType.MULI]: translations.machineTypeMuli,
  };
  return mapping;
};
