import { MachineType } from '@website-shared-library/machine/MachineType'
import { TLocale } from '@website-shared-library/machine/i18n/Locale'
import { TUnconfirmedMachineAttributesMap } from './TUnconfirmedMachineAttributesMap'
import { getUnconfirmedMachineAttributesMap } from './getUnconfirmedMachineAttributesMap'

const sanitizeWheelDepth = (wheelDepth: number | undefined | null) => {
  if (typeof wheelDepth === 'number' && wheelDepth > 1) {
    return wheelDepth / 100
  }
  return wheelDepth
}

export enum MediaCategory {
  Standard = 'standard',
  Abnormality = 'abnormality',
}
export type MediaDTO = {
  DEWATERMARKED_URL?: string
  EFARM_WATERMARKED_URL?: string
  HASHES?: {
    AHASH: string
    DHASH: string
    PHASH: string
    WHASH: string
  }
  MEDIA_ATTRIBUTE?: string
  MEDIA_CATEGORY?: MediaCategory
  MEDIA_DATE?: string | Date
  MEDIA_DELETED?: boolean
  MEDIA_HEIGHT?: number
  MEDIA_ID?: string
  MEDIA_MIME_TYPE?: string
  MEDIA_SOURCE?: string
  MEDIA_URL?: string
  MEDIA_WIDTH?: number
  ORIGINAL_URL?: string
}

enum MachineDataSource {
  agmachinery = 'agmachinery',
  agriaffaires = 'agriaffaires',
  agriaffaires_fork = 'agriaffaires_fork',
  farmerapp = 'farmerapp',
  internal = 'internal',
  landwirt = 'landwirt',
  machinefinder = 'machinefinder',
  mascus = 'mascus',
  mia = 'mia',
  model_metadata = 'model_metadata',
  picasso = 'picasso',
  reap = 'reap',
  smarttrade = 'smarttrade',
  technikboerse = 'technikboerse',
  tractomarket = 'tractomarket',
  traktorpool = 'traktorpool',
  vin = 'vin',
  zoho = 'zoho',
}

export type MandatoryMachineData = {
  MACHINE_GROUP_ID: string
  MACHINE_TYPE: MachineType
  MODEL: string | null
}

export type OptionlMachineData = {
  ABNORMALITIES?: unknown[]
  AD_SCORE_REL?: number
  AD_SCORE?: number
  AD_URL?: string[]
  AUCTION?: boolean
  AVAILABLE_URLS?: string[]
  AVAILABLE?: boolean
  BALES_TOTAL_NUMBER?: number
  BASE_MODEL?: string | null
  BRAND?: string
  CONDITION?: string
  CURRENCY?: string
  DATE_OF_CREATION?: string
  DATE_OF_PUBLICATION_UPDATE?: string
  DATE_OF_PUBLICATION?: string
  DRUM_HOURS?: number
  E_FARM_CLASSIFIEDS?: unknown[]
  ENGINE_HOURS?: number
  ENGINE_HP?: number
  ENGINE_KW?: number
  FORCE_SYNC?: boolean
  FREE_TEXT_FIELD?: string | null
  FREE_TEXT_PARSED?: unknown
  ID?: string
  KEY_SOURCES?: Record<string, MachineDataSource>
  LOCATION_CITY?: string
  LOCATION_COUNTRY?: string
  MACHINE_SERIAL_NUMBER?: string
  MAPPED_MODEL?: string
  MERGEABLE?: boolean
  MODEL_EXTENSION?: string | null
  MODEL_SERIES?: string | null
  PRICE_EXPORT?: number
  PRICE_NET_EUR?: number
  PRICE_NET?: number
  PROCESSED_MEDIA?: MediaDTO[]
  RAW_MODEL?: string | null
  SHOP_SCORE_REL?: number
  SHOP_SCORE?: number
  SOURCE?: string[]
  SOURCES_INTERNAL_ID?: string
  SOURCES?: unknown[]
  WHEEL_FRONT_LEFT_DEPTH?: number | null
  WHEEL_FRONT_RIGHT_DEPTH?: number | null
  WHEEL_REAR_LEFT_DEPTH?: number | null
  WHEEL_REAR_RIGHT_DEPTH?: number | null
  YEAR_OF_PRODUCTION?: number
  MACHINE_AVAILABILITY_FOR_SALE_DATE?: string | null
  REFERENCE?: string | null
}

export type MachineData = MandatoryMachineData & OptionlMachineData

export type MachineDataAttribute = keyof MachineData

export enum MachineMediaSource {
  InspectionApp = 'mia', // MachineInspectionApp,
  SmartTrade = 'smarttrade',
  External = 'external',
}

export type MachineMedia = {
  url: string
  mime: string
  attribute?: string
  width?: number
  height?: number
  ratio?: number
}

export enum FinancingLogo {
  Dll = 'dll',
  EFarm = 'e-farm',
}

export type Machine = {
  data: MachineData
  media: MachineMedia[]
  leasingQualified: boolean
  leasingInfo: {
    countryCode: TLocale // should be an enum for country codes, not languages
    logo: FinancingLogo
    pmt: number
  } | null
  machineGroupId: string
  brand: string
  name: string
  model: string
  machineType: MachineType
  yearOfProduction: number | null
  enginePower: number | null
  engineHours: number | null
  price: number | null
  previewImage: MachineMedia | null
  availability: boolean
  balesTotalNumber?: number | null
  drumHours?: number | null
  allowIndexing: boolean
  unconfirmedAttributes: TUnconfirmedMachineAttributesMap
  locationCountry: string | null
}

export type OldMachineDTO = {
  media?: MachineMedia[]
  dealerStatus: string
  excludedCountriesForSerp: string[]
  createdAt: string
  updatedAt: string
  brand: string
  leasingQualified?: boolean
  leasingInfo?: {
    countryCode: TLocale
    logo: FinancingLogo
    pmt: number
  } | null
  data: MachineData
}

const sanitizeData = (data: MachineData) => {
  // extract keys that we certainly don't need or don't want to expose
  const {
    ABNORMALITIES,
    AD_SCORE_REL,
    AD_SCORE,
    AD_URL,
    AUCTION,
    AVAILABLE_URLS,
    CONDITION,
    CURRENCY,
    DATE_OF_CREATION,
    DATE_OF_PUBLICATION_UPDATE,
    DATE_OF_PUBLICATION,
    E_FARM_CLASSIFIEDS,
    FREE_TEXT_FIELD,
    ENGINE_KW,
    FORCE_SYNC,
    FREE_TEXT_PARSED,
    ID,
    KEY_SOURCES,
    LOCATION_CITY,
    MACHINE_SERIAL_NUMBER,
    MERGEABLE,
    MODEL_EXTENSION,
    MODEL_SERIES,
    PRICE_EXPORT,
    PRICE_NET,
    PROCESSED_MEDIA,
    SHOP_SCORE_REL,
    SHOP_SCORE,
    SOURCE,
    SOURCES_INTERNAL_ID,
    SOURCES,
    ...cleanData
  } = data

  return cleanData
}

export const reduceToMinimalMachine = (machine: Machine): Machine => ({
  ...machine,
  media: [],
  data: {
    MACHINE_GROUP_ID: machine.data.MACHINE_GROUP_ID,
    MACHINE_TYPE: machine.data.MACHINE_TYPE,
    MODEL: machine.data.MODEL,
  },
  unconfirmedAttributes: {},
})

// cannot omitBy from lodash, because that crashes in  Edge Runtime 🤷‍♂️
const omitUndefined = <T extends Record<string, unknown>>(data: T) =>
  Object.entries(data).reduce<Record<string, unknown>>((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key] = value
    }
    return acc
  }, {})

export const oldMachineDTOtoMachine = ({
  media,
  dealerStatus,
  excludedCountriesForSerp,
  createdAt,
  updatedAt,
  brand,
  leasingQualified,
  leasingInfo,
  data,
}: OldMachineDTO): Machine => {
  const model = data.MAPPED_MODEL ?? data.MODEL ?? data.RAW_MODEL ?? ''
  const previewImage = media?.[0] ?? null

  const sanitizedData: MachineData = {
    ...sanitizeData(data),
    WHEEL_FRONT_LEFT_DEPTH: sanitizeWheelDepth(data.WHEEL_FRONT_LEFT_DEPTH),
    WHEEL_FRONT_RIGHT_DEPTH: sanitizeWheelDepth(data.WHEEL_FRONT_RIGHT_DEPTH),
    WHEEL_REAR_LEFT_DEPTH: sanitizeWheelDepth(data.WHEEL_REAR_LEFT_DEPTH),
    WHEEL_REAR_RIGHT_DEPTH: sanitizeWheelDepth(data.WHEEL_REAR_RIGHT_DEPTH),
  }

  if (
    data.KEY_SOURCES?.['FREE_TEXT_FIELD'] === MachineDataSource.smarttrade &&
    data?.FREE_TEXT_FIELD
  ) {
    sanitizedData.FREE_TEXT_FIELD = data.FREE_TEXT_FIELD
  }

  return {
    data: omitUndefined(sanitizedData) as MachineData,
    media: media ?? [],
    leasingQualified: !!leasingQualified,
    leasingInfo: leasingInfo ?? null,
    machineGroupId: data.MACHINE_GROUP_ID,
    brand,
    model,
    name: `${brand} ${model}`.trim(),
    machineType: data.MACHINE_TYPE,
    yearOfProduction: data.YEAR_OF_PRODUCTION ?? null,
    enginePower: data.ENGINE_HP ?? null,
    engineHours: data.ENGINE_HOURS ?? null,
    price: data.PRICE_NET_EUR ?? null,
    previewImage,
    availability: !!data.AVAILABLE,
    balesTotalNumber: data.BALES_TOTAL_NUMBER ?? null,
    drumHours: data.DRUM_HOURS ?? null,
    allowIndexing: dealerStatus === 'Onboarded' && !!previewImage,
    unconfirmedAttributes: getUnconfirmedMachineAttributesMap(data.KEY_SOURCES),
    locationCountry: data.LOCATION_COUNTRY ?? null,
  }
}
