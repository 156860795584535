import { getLocalImage } from '@internal/utils/media/getLocalImage'
import { getMediaSrc } from '@internal/utils/media/getMediaSrc'
import { Typography } from '@renderer-ui-library/mui'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { Machine } from '@internal/utils/machine/Machine'
import { TLocale } from '@website-shared-library/machine/i18n/Locale'
import classNames from 'classnames'
import 'lazysizes'
import Head from 'next/head'
import Image from 'next/image'
import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { TMedia } from '../Media/TMedia'
import { MediaSliderOverlay } from '../MediaSliderOverlay/MediaSliderOverlay'
import { ShareButton } from '../ShareButton/ShareButton'
import styles from './thumbnailSlider.module.scss'

const soldStamp = getLocalImage(
  require('@renderer-ui-library/assets/sold-stamp.svg'),
  'image/svg+xml'
)

export type ThumbnailSliderProps = {
  media: TMedia[]
  imageRatio: number
  machine: Machine
  locale: TLocale
  slidesWrapperClassName?: string
  hasMediaOverlay?: boolean
  pagination?: boolean
  isAvailable?: boolean
  hideShareButton?: boolean
}

export const ThumbnailSlider: React.FC<ThumbnailSliderProps> = React.memo(
  (props) => {
    const [open, setOpen] = React.useState<boolean>(false)
    const [activeIndex, setActiveIndex] = useState<number>(0)

    const mainSliderRef = createRef<Splide>()
    const thumbSliderRef = createRef<Splide>()

    useEffect(() => {
      if (mainSliderRef.current && thumbSliderRef?.current?.splide) {
        mainSliderRef.current.sync(thumbSliderRef.current.splide)
      }
    }, [mainSliderRef, thumbSliderRef])

    useEffect(() => {
      if (mainSliderRef.current && thumbSliderRef.current) {
        mainSliderRef.current.go(activeIndex)
        thumbSliderRef.current.go(activeIndex)
      }
    }, [activeIndex, mainSliderRef, thumbSliderRef])

    const slides = useMemo(
      () =>
        props.media.map((media, index) => (
          <SplideSlide key={`${index}=${media.attributes.url}`}>
            {!props.isAvailable && (
              <div className={styles.soldOverlay}>
                <Image
                  alt='sold machine'
                  src={soldStamp.attributes.url}
                  className={styles.soldStamp}
                />
              </div>
            )}
            <img
              loading='lazy'
              data-src={
                getMediaSrc(
                  { data: media },
                  {
                    ratio:
                      typeof media.attributes.ratio === 'number' &&
                      media.attributes.ratio <= 1
                        ? 'original'
                        : props.imageRatio,
                    isExtraLarge: true,
                  }
                ).src
              }
              data-srcset={
                getMediaSrc(
                  { data: media },
                  {
                    ratio:
                      typeof media.attributes.ratio === 'number' &&
                      media.attributes.ratio <= 1
                        ? 'original'
                        : props.imageRatio,
                    isExtraLarge: true,
                  }
                ).srcSet
              }
              className={classNames(styles.image, 'lazyload', {
                [styles.cover]:
                  media.attributes.ratio === undefined ||
                  media.attributes.ratio > 1,
                [styles.contain]:
                  typeof media.attributes.ratio === 'number' &&
                  media.attributes.ratio <= 1,
                [styles.clickable]: props.hasMediaOverlay,
                [styles.grayScale]: !props.isAvailable,
              })}
              alt={media.attributes.alternativeText ?? ''}
            />
          </SplideSlide>
        )),
      [props.hasMediaOverlay, props.imageRatio, props.isAvailable, props.media]
    )

    const handleSlideChange = useCallback(
      (_: any, newIndex: number) => setActiveIndex(newIndex),
      []
    )

    const handleMediaSlideChange = useCallback((activeIndex: number) => {
      setActiveIndex(activeIndex)
    }, [])

    const handleOpen = useCallback(() => {
      if (props.hasMediaOverlay) {
        setOpen(true)
      }
    }, [props.hasMediaOverlay])

    const handleClose = useCallback(() => setOpen(false), [])

    const renderPagination = useMemo(
      () =>
        props.pagination ? (
          <div className={styles.pagination}>
            <Typography variant='body2' color='white'>
              {activeIndex + 1}/{props.media.length}
            </Typography>
          </div>
        ) : null,
      [activeIndex, props.media.length, props.pagination]
    )

    return (
      <>
        <Head>
          <link
            as='image'
            rel='preload'
            href={
              getMediaSrc(
                { data: props.media[0] },
                {
                  ratio:
                    typeof props.media[0].attributes.ratio === 'number' &&
                    props.media[0].attributes.ratio <= 1
                      ? 'original'
                      : props.imageRatio,
                  isExtraLarge: true,
                }
              ).src
            }
            imageSrcSet={
              getMediaSrc(
                { data: props.media[0] },
                {
                  ratio:
                    typeof props.media[0].attributes.ratio === 'number' &&
                    props.media[0].attributes.ratio <= 1
                      ? 'original'
                      : props.imageRatio,
                  isExtraLarge: true,
                }
              ).srcSet
            }
          />
        </Head>
        <div className={classNames(styles.main, props.slidesWrapperClassName)}>
          <div
            className={classNames(styles.shareButtonWrapper, {
              [styles.hideShareButton]: props.hideShareButton,
            })}
          >
            <ShareButton
              machine={props.machine}
              positionList='bottom'
              locale={props.locale}
              variant='large'
            />
          </div>
          <Splide
            ref={mainSliderRef}
            options={{
              width: '100%',
              heightRatio: 1 / props.imageRatio,
              pagination: false,
              easing: 'ease',
              lazyLoad: 'nearby',
              arrows: true,
            }}
            onMoved={handleSlideChange}
            onClick={handleOpen}
          >
            {slides}
          </Splide>
          {renderPagination}
        </div>

        {props.media.length > 1 && (
          <div className={styles.thumbnails}>
            <Splide
              ref={thumbSliderRef}
              options={{
                fixedWidth: '14%',
                fixedHeight: 85,
                gap: 10,
                cover: true,
                pagination: false,
                arrows: false,
                easing: 'ease',
                focus: 'center',
                isNavigation: true,
                lazyLoad: 'nearby',
                preloadPages: 7,
              }}
            >
              {slides}
            </Splide>
          </div>
        )}

        {props.hasMediaOverlay && (
          <MediaSliderOverlay
            open={open}
            media={props.media}
            onClose={handleClose}
            handleMediaChange={handleMediaSlideChange}
            imageRatio={props.imageRatio}
            activeIndex={activeIndex}
          />
        )}
      </>
    )
  }
)

ThumbnailSlider.displayName = 'ThumbnailSlider'
