import { TLocalizedUrlMap } from '@internal/i18n/TLocalizedUrlMap'
import { Machine } from '@internal/utils/machine/Machine'
import React, { useEffect, useState } from 'react'
import { MachineContext } from './MachineContext'

interface MachineProviderProps {
  machine: Machine | null
  localizedUrlMap: TLocalizedUrlMap | null
  children: React.ReactNode
}

export const MachineProvider: React.FC<MachineProviderProps> = React.memo(
  (props) => {
    const [machine, setMachine] = useState<Machine | null>(null)
    useEffect(() => setMachine(props.machine), [props.machine])

    return (
      <MachineContext.Provider
        value={{
          machine,
          updateMachine: setMachine,
          localizedUrlMap: props.localizedUrlMap,
        }}
      >
        {props.children}
      </MachineContext.Provider>
    )
  }
)

MachineProvider.displayName = 'MachineProvider'
