import { Grid } from '@renderer-ui-library/mui'
import { useMachineCategoryMap } from 'utils/machine/useMachineCategoryMap'
import { MachineCategory } from '../MachineCategory/MachineCategory'
import styles from './allMachinesMenu.module.scss'

type Props = {
  onClick: () => void
}

export const AllMachinesMenu: React.FC<Props> = (props) => {
  const { machineCategoryMap } = useMachineCategoryMap()
  return (
    <>
      <div className={styles.machines}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            {machineCategoryMap.slice(0, 3).map((category) => (
              <MachineCategory
                key={category.name}
                category={category}
                onClick={props.onClick}
              />
            ))}
          </Grid>
          <Grid item xs={2}>
            {machineCategoryMap.slice(3, 5).map((category) => (
              <MachineCategory
                key={category.name}
                category={category}
                onClick={props.onClick}
              />
            ))}
            {machineCategoryMap.slice(5).map((category) => (
              <MachineCategory
                key={category.name}
                category={category}
                sliceEnd={4}
                onClick={props.onClick}
              />
            ))}
          </Grid>
          <Grid item xs={8}>
            <div className={styles.moreColumns}>
              {machineCategoryMap.slice(5).map((category) => (
                <MachineCategory
                  key={category.name}
                  category={category}
                  noHeadline
                  sliceStart={4}
                  onClick={props.onClick}
                />
              ))}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  )
}
