import { Machine } from '@internal/utils/machine/Machine'
import {
  CollapseArea,
  TTableRowValue,
  TableValueContent,
} from '@renderer-ui-library/molecules'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@renderer-ui-library/mui'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import { TDisplayDefinitionCategory } from '@website-shared-library/displayDefinition/TDisplayDefinitionCategory'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import classNames from 'classnames'
import dynamic from 'next/dynamic'
import React, { useCallback, useMemo } from 'react'
import { TUnconfirmedMachineAttributesMap } from 'utils/machine/TUnconfirmedMachineAttributesMap'
import { mapMachinesAttributesToTableRow } from 'utils/table/mapMachinesAttributesToTableRow'
import styles from './detailsTable.module.scss'

export type DetailsTableProps = {
  title: string
  locale: TLocale
  machines: Machine[]
  displayDefinition: TDisplayDefinitionCategory[]
  showOnlyDifferent: boolean
  showCategoriesTitle: boolean
  noPadding?: boolean
  noMargin?: boolean
}

export const DetailsTable: React.FC<DetailsTableProps> = React.memo(
  ({
    displayDefinition,
    machines,
    locale,
    title,
    showOnlyDifferent,
    showCategoriesTitle,
    noPadding,
    noMargin,
  }) => {
    const { isMobile } = useMediaQuery()

    const categories = useMemo(
      () =>
        displayDefinition.map(({ name, entries }) => ({
          name,
          rows: mapMachinesAttributesToTableRow(machines, entries, locale),
        })),
      [displayDefinition, locale, machines]
    )

    const allMachinesHaveSameValue = useCallback(
      (rowvalues: TTableRowValue[]) =>
        showOnlyDifferent &&
        machines.length > 1 &&
        rowvalues.every((item) => rowvalues.indexOf(item) === 0),
      [machines.length, showOnlyDifferent]
    )

    return (
      <div
        className={classNames(styles.wrapper, {
          [styles.noMargin]: noMargin,
          [styles.machines1]: machines.length === 1,
          [styles.machines2]: machines.length === 2,
          [styles.machines3]: machines.length === 3,
        })}
      >
        <CollapseArea title={title} reverseDirection>
          <div
            className={classNames(styles.tableWrapper, {
              [styles.noPadding]: noPadding,
            })}
          >
            <div className={styles.table}>
              <Table>
                <TableBody>
                  {categories.map(({ name, rows }) => (
                    <React.Fragment key={name}>
                      {rows.map((row, index) => (
                        <React.Fragment key={index}>
                          {showCategoriesTitle && index === 0 && (
                            <TableRow
                              className={classNames(styles.tableRow, {
                                [styles.noBorder]: index === 0,
                              })}
                            >
                              <TableCell
                                size='small'
                                component='th'
                                className={styles.titleRow}
                              >
                                <Typography
                                  variant='subtitle1'
                                  component='span'
                                  color='inherit'
                                >
                                  {name}
                                </Typography>
                              </TableCell>
                              {!isMobile &&
                                machines.map((machine) => (
                                  <TableCell
                                    key={machine.machineGroupId}
                                    size='small'
                                    component='th'
                                    className={styles.titleRow}
                                  />
                                ))}
                            </TableRow>
                          )}
                          <TableRow
                            key={`${row.key}-${index}`}
                            className={styles.tableRow}
                          >
                            <TableCell
                              size='small'
                              component='th'
                              className={styles.tableCell}
                            >
                              {row.key}
                            </TableCell>

                            {row.values.map((value, index) => (
                              <TableCell
                                key={`${value}-${index}`}
                                size='small'
                                className={classNames(styles.tableCell, {
                                  [styles.tableCellDisabled]:
                                    allMachinesHaveSameValue(row.values),
                                })}
                              >
                                <TableValueContent
                                  value={value}
                                  marker={row.marker}
                                />
                              </TableCell>
                            ))}
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </CollapseArea>
      </div>
    )
  }
)

DetailsTable.displayName = 'DetailsTable'

export const DynamicDetailsTable = dynamic<DetailsTableProps>(
  () => import('./DetailsTable').then((md) => md.DetailsTable),
  { ssr: false }
)
