import { useMachineUrl } from '@internal/utils/routing/hooks/useMachineUrl'
import { CmsClickEventTargetName } from '@internal/utils/tracking/TTrackingEvent'
import { tracker } from '@internal/utils/tracking/tracker'
import { ContentCopy, Share } from '@mui/icons-material'
import { ClickToCopyWrapper } from '@renderer-ui-library/molecules'
import {
  Button,
  IconButton,
  Popover,
  Typography,
} from '@renderer-ui-library/mui'
import { Machine } from '@internal/utils/machine/Machine'
import {
  TLocale,
  localeNamespace,
} from '@website-shared-library/machine/i18n/Locale'
import classNames from 'classnames'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { useCallback, useMemo, useState } from 'react'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
import styles from './shareButton.module.scss'

type Props = {
  machine: Machine
  positionList: 'top' | 'bottom'
  locale: TLocale
  variant: 'small' | 'large'
}

export const ShareButton: React.FC<Props> = ({
  machine,
  positionList,
  locale,
  variant,
}) => {
  const { getMachineUrl } = useMachineUrl()
  const url = getMachineUrl(locale, machine)
  const { t } = useTranslation(localeNamespace.common)
  const [anchorElement, setAnchorElement] =
    useState<HTMLButtonElement | null>(null)

  const shareLinkUrl = 'https://e-farm.com' + url

  const [showShareList, setShowShareList] = useState(false)

  const handleToggleShareClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setShowShareList((prev) => !prev)
      setAnchorElement(event.currentTarget)
    },
    []
  )

  const handleClosePopover = useCallback(() => {
    setShowShareList((prev) => !prev)
    setAnchorElement(null)
  }, [])

  const handleShareButtonClickTracking = useMemo(() => {
    const track = (target: CmsClickEventTargetName) =>
      tracker.trackEvent({
        name: 'click',
        target_name: target,
        is_interaction: true,
      })

    return {
      whatsapp: () => track('share_machine.whatsapp'),
      facebook: () => track('share_machine.facebook'),
      linkedin: () => track('share_machine.linkedin'),
      mail: () => track('share_machine.mail'),
      link: () => track('share_machine.link'),
    }
  }, [])

  return useMemo(
    () => (
      <>
        {variant === 'large' && (
          <Button
            disableRipple
            disableFocusRipple
            disableTouchRipple
            className={styles.shareLinkButton}
            onClick={handleToggleShareClick}
            id='toggleSharePopover'
          >
            <Share fontSize='small' />
            <Typography variant='body1' color='primary'>
              {t(translations.shareButtonCta)}
            </Typography>
          </Button>
        )}

        {variant === 'small' && (
          <IconButton
            size='small'
            color='primary'
            onClick={handleToggleShareClick}
          >
            <Share />
          </IconButton>
        )}

        <Popover
          id='toggleSharePopover'
          open={showShareList}
          anchorEl={anchorElement}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: positionList === 'top' ? 'top' : 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: positionList === 'top' ? 'bottom' : 'top',
            horizontal: 'center',
          }}
          sx={{ borderRadius: '8px' }}
        >
          <div className={classNames(styles.shareLinkList)}>
            <WhatsappShareButton
              url={shareLinkUrl}
              onClick={handleShareButtonClickTracking.whatsapp}
              className={classNames(styles.shareIconButton)}
            >
              <WhatsappIcon size={32} />
            </WhatsappShareButton>
            <FacebookShareButton
              url={shareLinkUrl}
              onClick={handleShareButtonClickTracking.facebook}
              className={classNames(styles.shareIconButton)}
            >
              <FacebookIcon size={32} />
            </FacebookShareButton>
            <LinkedinShareButton
              onClick={handleShareButtonClickTracking.linkedin}
              url={shareLinkUrl}
              className={classNames(styles.shareIconButton)}
            >
              <LinkedinIcon size={32} />
            </LinkedinShareButton>
            <EmailShareButton
              onClick={handleShareButtonClickTracking.mail}
              url={shareLinkUrl}
              className={classNames(styles.shareIconButton)}
            >
              <EmailIcon size={32} />
            </EmailShareButton>
            <Button
              className={classNames(styles.shareIconButton)}
              onClick={handleShareButtonClickTracking.link}
            >
              <ClickToCopyWrapper text={shareLinkUrl}>
                <ContentCopy />
              </ClickToCopyWrapper>
            </Button>
          </div>
        </Popover>
      </>
    ),
    [
      anchorElement,
      handleClosePopover,
      handleShareButtonClickTracking.facebook,
      handleShareButtonClickTracking.link,
      handleShareButtonClickTracking.linkedin,
      handleShareButtonClickTracking.mail,
      handleShareButtonClickTracking.whatsapp,
      handleToggleShareClick,
      positionList,
      shareLinkUrl,
      showShareList,
      t,
      variant,
    ]
  )
}

ShareButton.displayName = 'ShareButton'
