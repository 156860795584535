import {
  localeNamespace,
  TLocale,
} from '@website-shared-library/machine/i18n/Locale'
import { MachineType } from '@website-shared-library/machine/MachineType'
import i18next from 'i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { machineTypeTranslationKeyMapping } from 'utils/machineTypeTranslationKeyMapping'

export const Plural = 100
const Singular = 0

export const getSSRMachineTypeName = async (
  locale: TLocale,
  machineType: MachineType,
  count: number = Singular
) => {
  const { _nextI18Next } = await serverSideTranslations(locale, [
    localeNamespace.common,
  ])

  const _i18n = i18next.createInstance()

  _i18n.init({
    lng: locale,
    ns: localeNamespace.common,
    resources: await _nextI18Next.initialI18nStore,
    fallbackLng: await _nextI18Next.userConfig?.i18n.defaultLocale,
  })

  const text = (await _i18n.t(machineTypeTranslationKeyMapping[machineType], {
    count,
  })) as string

  return text
}
