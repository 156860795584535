import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
} from '@mui/icons-material'
import { Checkbox, FormControlLabel } from '@renderer-ui-library/mui'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { useSearchParams } from 'blocks/SearchResultsBlock/useSearchParams'
import { useSearchResultsRouting } from 'blocks/SearchResultsBlock/useSearchResultsRouting'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { useCallback } from 'react'
import styles from './machineImageFiler.module.scss'

export const MachineImageFilter = React.memo(() => {
  const { excludeWithoutMedia, ...searchParams } = useSearchParams()
  const { gotoSearchResults } = useSearchResultsRouting()
  const { t } = useTranslation(localeNamespace.common)

  const handleCheckboxChange = useCallback(
    (_: any, checked: boolean) => {
      gotoSearchResults({
        ...searchParams,
        excludeWithoutMedia: checked,
      })
    },
    [gotoSearchResults, searchParams]
  )

  return (
    <div className={styles.excludeWrapper}>
      <FormControlLabel
        control={
          <Checkbox
            icon={<CheckBoxOutlineBlank fontSize='small' />}
            checkedIcon={<CheckBoxIcon fontSize='small' />}
            className={styles.exludeMediaCheckbox}
            checked={excludeWithoutMedia}
            onChange={handleCheckboxChange}
          />
        }
        label={t(
          translations.searchResultsFilterExcludeMachineWithoutImageCheckboxLabel
        ).toString()}
      />
    </div>
  )
})

MachineImageFilter.displayName = 'MachineImageFilter'
