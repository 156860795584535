import {
  MachineType,
  SubTypes,
} from '@website-shared-library/machine/MachineType'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import { TFilterableBaleChamberOption } from '@website-shared-library/search/TFilterableBalerChamberOption'
import { TFilterableHeaderTypeOption } from '@website-shared-library/search/TFilterableHeaderTypeOption'
import { TFilterableMachineAttribute } from '@website-shared-library/search/TFilterableMachineAttribute'
import { useRouter } from 'next/router'
import { SystemPageKey } from 'page/SystemPageKey'
import { useCallback } from 'react'
import { defaultLocale } from 'utils/constants'
import { TSearchResultsRouteParams } from 'utils/routing/routingTable'
import { urlFor } from 'utils/routing/urls/urlFor'
import { SearchResultsSortKey } from './SearchResultsSortKey'
import { TRangeFiltersMap } from './TRangeFiltersMap'
import { loadChunkSize } from './loadChunkSize'
import { maxSearchResults } from './maxSearchResults'
import { mappedModelSuffix, SearchModel } from './TSearchParams'

const machineAttributeToSlug = (attribute: TFilterableMachineAttribute) =>
  attribute.toLowerCase().replace(/_/g, '-')

const rangeFilterToQuery = (
  attribute: TFilterableMachineAttribute,
  data: {
    min?: number
    max?: number
    excludeMachinesWithoutValue?: boolean
  }
) => {
  let value =
    data.min || data.max || data.excludeMachinesWithoutValue
      ? `${data.min ? Math.floor(data.min) : ''}-${
          data.max ? Math.floor(data.max) : ''
        }${data.excludeMachinesWithoutValue ? 'x' : ''}`
      : null

  if (!value) {
    return null
  }

  return {
    key: machineAttributeToSlug(attribute),
    value,
  }
}

export type Params = Omit<
  TSearchResultsRouteParams['queryParams'],
  | 'machineType'
  | 'machineSubType'
  | 'baleChamber'
  | 'headerType'
  | 'amount'
  | 'brand'
  | 'location'
  | 'feature'
  | 'excludeWithoutMedia'
  | 'model'
> & {
  amount?: number
  baleChamber?: TFilterableBaleChamberOption
  headerType?: TFilterableHeaderTypeOption
  rangeFilters?: TRangeFiltersMap
  brands?: string[]
  locations?: string[]
  features?: TFilterableMachineAttribute[]
  sort?: SearchResultsSortKey
  machineType?: MachineType
  machineSubType?: SubTypes[keyof SubTypes]
  excludeWithoutMedia?: boolean
  model?: SearchModel | undefined
}

export const useSearchResultsRouting = () => {
  const { push, replace, locale } = useRouter()

  const getSearchResultsUrl = useCallback(
    (params: Params) => {
      const queryParams: TSearchResultsRouteParams['queryParams'] = {
        brand: params.brands,
        // one can only search by model if exactly one brand is selected
        model:
          params.brands && params.brands.length === 1 && params.model
            ? params.model.isMapped
              ? `${params.model.name}${mappedModelSuffix}`
              : params.model.name
            : undefined,
        amount:
          params.amount && params.amount !== loadChunkSize
            ? Math.min(maxSearchResults, params.amount).toString()
            : undefined,
        location: params.locations?.map((location) => location.toLowerCase()),
        feature: params.features?.map(machineAttributeToSlug),
        sort:
          params.sort !== SearchResultsSortKey.RelevanceAsc
            ? params.sort
            : undefined,
      }

      if (params.machineType) {
        queryParams.machineType = params.machineType
      }

      if (params.machineSubType) {
        queryParams.machineSubType = params.machineSubType.toString()
      }

      if (params.baleChamber) {
        queryParams.baleChamber = params.baleChamber.toString()
      }

      if (params.headerType) {
        queryParams.headerType = params.headerType.toString()
      }

      if (params.rangeFilters) {
        Object.keys(params.rangeFilters).forEach((attribute) => {
          const data = params.rangeFilters![attribute]
          const query = rangeFilterToQuery(
            attribute as TFilterableMachineAttribute,
            data
          )
          if (query) {
            ;(queryParams as any)[query.key] = query.value
          }
        })
      }

      if (params.excludeWithoutMedia === false) {
        queryParams.excludeWithoutMedia = 'false'
      }

      const url = urlFor((locale as TLocale) ?? defaultLocale, {
        key: SystemPageKey.SearchResults,
        pathParams: {},
        queryParams,
      })

      return url
    },
    [locale]
  )

  const gotoSearchResults = useCallback(
    (
      params: Params & {
        replace?: boolean
      },
      shallow = true
    ) => {
      const url = getSearchResultsUrl(params)

      if (params.replace) {
        replace(url, undefined, { shallow })
      } else {
        push(url, undefined, { shallow })
      }
    },
    [getSearchResultsUrl, push, replace]
  )

  return { gotoSearchResults, getSearchResultsUrl }
}
