import { machineMapping } from '@website-shared-library/machine/i18n/machineMapping'
import { TranslationJsonType } from '@website-shared-library/machine/i18n/Translation'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { translations } from 'i18n/translations'

const mapping = machineMapping(translations)

export const machineTypeTranslationKeyMapping = mapping as any as Record<
  MachineType,
  keyof TranslationJsonType
>
