import { translations } from '@internal/i18n/translations'
import { MachineContext } from '@internal/utils/machine/MachineContext'
import {
  CallOutlined as CallOutlinedIcon,
  Close as CloseIcon,
} from '@mui/icons-material'
import { ClickToCopyWrapper } from '@renderer-ui-library/molecules'
import { Paper, Slide } from '@renderer-ui-library/mui'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import { useCallback, useContext, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { tracker } from 'utils/tracking/tracker'
import styles from './headerPhoneNumber.module.scss'

export type HeaderPhoneNumberProps = {
  number: string
}

const buttonStyles = classNames(styles.serviceNumberReveal, styles.cluster)

export const HeaderPhoneNumber = ({ number }: HeaderPhoneNumberProps) => {
  const [isPhoneVisible, setIsPhoneVisible] = useState(false)
  const { t } = useTranslation(localeNamespace.common)
  const { machine } = useContext(MachineContext)

  const close = useCallback(() => setIsPhoneVisible(false), [])

  const handleButtonClick = useCallback(() => {
    setIsPhoneVisible(true)

    tracker.trackEvent({
      name: 'call-click',
      target_name: 'header',
      machine_group_id: machine?.machineGroupId,
      is_interaction: true,
    })
  }, [machine])

  return (
    <div className={styles.cluster}>
      <button onClick={handleButtonClick} className={buttonStyles}>
        <CallOutlinedIcon fontSize='small' />
        <span>{t(translations.searchResultsNoResultsContactButton)}</span>
      </button>
      <PhoneSlideInNumber
        isOpen={isPhoneVisible}
        number={number}
        onClose={close}
      />
    </div>
  )
}

type PhoneSlideInNumberProps = {
  isOpen: boolean
  number: string
  onClose: () => void
}

const PhoneSlideInNumber = ({
  isOpen,
  number,
  onClose,
}: PhoneSlideInNumberProps) => {
  return (
    <div className={styles.slideContainer}>
      <Slide direction='right' in={isOpen} mountOnEnter unmountOnExit>
        <Paper elevation={4} className={styles.overlay}>
          <div className={styles.cluster}>
            <ClickToCopyWrapper text={number}>
              <div className={styles.cluster}>
                <CallOutlinedIcon />
                <ReactMarkdown className={styles.serviceNumber}>
                  {number}
                </ReactMarkdown>
              </div>
            </ClickToCopyWrapper>
            <CloseIcon onClick={onClose} className={styles.close} />
          </div>
        </Paper>
      </Slide>
    </div>
  )
}
