import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
} from '@mui/icons-material'
import { RangeInput } from '@renderer-ui-library/molecules'
import { Checkbox, FormControlLabel } from '@renderer-ui-library/mui'
import { trackRangeFilterChange } from '@renderer-ui-library/organisms'
import { TDisplayDefinitionEntryNumber } from '@website-shared-library/displayDefinition/entry/DisplayDefinitionEntryNumber'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { TFilterableMachineAttribute } from '@website-shared-library/search/TFilterableMachineAttribute'
import { RangeSelectValue } from 'blocks/SearchCoverBlock/RangeSelectFilter'
import { TRangeFilterValues } from 'blocks/SearchResultsBlock/TRangeFilterValues'
import { useRangeFilterOptions } from 'blocks/SearchResultsBlock/useRangeFilterOptions'
import { useSearchParams } from 'blocks/SearchResultsBlock/useSearchParams'
import { useSearchResultsRouting } from 'blocks/SearchResultsBlock/useSearchResultsRouting'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import React, { useCallback } from 'react'
import { SearchFilterCategory } from './SearchFilterCategory/SearchFilterCategory'
import styles from './rangeFilter.module.scss'

type Props = {
  config: TDisplayDefinitionEntryNumber
}

export const RangeFilter: React.FC<Props> = React.memo((props) => {
  const { rangeFilters, ...searchParams } = useSearchParams()
  const { gotoSearchResults } = useSearchResultsRouting()
  const { t } = useTranslation(localeNamespace.common)
  const filter: TRangeFilterValues | undefined =
    rangeFilters[props.config.machineAttribute]

  const rangeFilterOptions = useRangeFilterOptions(
    props.config.machineAttribute as TFilterableMachineAttribute,
    props.config.label
  )

  const handleRangeChange = useCallback(
    (value: { min?: RangeSelectValue; max?: RangeSelectValue }) => {
      gotoSearchResults({
        ...searchParams,
        rangeFilters: {
          ...rangeFilters,
          [props.config.machineAttribute]: {
            ...filter,
            ...value,
          },
        },
      })

      trackRangeFilterChange(
        props.config.machineAttribute as TFilterableMachineAttribute,
        value
      )
    },
    [gotoSearchResults, searchParams, rangeFilters, props.config, filter]
  )

  const handleCheckboxClick = useCallback(() => {
    const excludeMachinesWithoutValue = !filter?.excludeMachinesWithoutValue
    gotoSearchResults({
      ...searchParams,
      rangeFilters: {
        ...rangeFilters,
        [props.config.machineAttribute]: {
          ...filter,
          excludeMachinesWithoutValue,
        },
      },
    })
  }, [
    gotoSearchResults,
    searchParams,
    rangeFilters,
    props.config.machineAttribute,
    filter,
  ])

  return (
    <SearchFilterCategory title={rangeFilterOptions.label}>
      <RangeInput
        {...rangeFilterOptions}
        minValue={filter?.min}
        maxValue={filter?.max}
        step={1}
        onChange={handleRangeChange}
      />

      <div className={styles.excludeWrapper}>
        <FormControlLabel
          control={
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize='small' />}
              checkedIcon={<CheckBoxIcon fontSize='small' />}
              checked={!!filter?.excludeMachinesWithoutValue}
              onClick={handleCheckboxClick}
            />
          }
          slotProps={{
            typography: {
              variant: 'caption',
              style: { lineHeight: 1.2 },
            },
          }}
          label={t(
            translations.searchResultsFilterExcludeCheckboxLabel
          ).toString()}
        />
      </div>
    </SearchFilterCategory>
  )
})

RangeFilter.displayName = 'RangeFilter'
