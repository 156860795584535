import { createContext, FC, memo, PropsWithChildren } from 'react'

export type Settings = {
  auctionLive: boolean
  rollout1: number
  rollout2: number
}

export const SettingsContext = createContext<Settings>({
  auctionLive: false,
  rollout1: 0,
  rollout2: 0,
})

export const SettingsProvider: FC<PropsWithChildren<{ settings: Settings }>> =
  memo(({ children, settings }) => (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  ))

SettingsProvider.displayName = 'SettingsProvider'
