import { MachineType } from '@website-shared-library/machine/MachineType'
import { getServerSideTranslations } from 'i18n/getServerSideTranslations'
import { SystemPageKey } from 'page/SystemPageKey'
import { getMachineMeta } from 'services/unauthenticatedBackendClient'
import { machineTypeTranslationKeyMapping } from 'utils/machineTypeTranslationKeyMapping'
import { buildMachineUrlPathParams } from 'utils/routing/buildMachineUrlPathParams'
import { urlFor } from '../urls/urlFor'
import { TRedirectGenerator } from './TRedirectGenerator'

export const getRedirectForMachine: TRedirectGenerator<{
  machineGroupId?: string
}> = async ({ pathParams, locale, queryParams }) => {
  const machineGroupId = pathParams.machineGroupId
  if (!machineGroupId) {
    return false
  }

  try {
    const machine = await getMachineMeta(machineGroupId)

    return urlFor(locale, {
      key: SystemPageKey.PDP,
      pathParams: {
        ...buildMachineUrlPathParams(locale, {
          machineGroupId,
          brand: machine.data.BRAND ?? '',
          model: machine.data.MODEL ?? '',
          machineTypeName: getServerSideTranslations(
            locale,
            machineTypeTranslationKeyMapping[
              machine.data.MACHINE_TYPE as MachineType
            ]
          ),
        }),
      },
      queryParams,
    })
  } catch (err) {
    console.error(err)
    return false
  }
}
