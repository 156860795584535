import { Machine, MachineMedia } from '@internal/utils/machine/Machine'
import { MachinePlaceholderImageMapper } from '@internal/utils/machine/placeholder/MachinePlaceholderImageMapper'
import { Close } from '@mui/icons-material'
import { Button } from '@renderer-ui-library/atoms'
import { CompareMachinesImageSlider } from '@renderer-ui-library/molecules'
import { Typography } from '@renderer-ui-library/mui'
import { ImageRatio } from '@renderer-ui-library/mui/utils/media/ImageRatio'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import { ContactWizardContext } from '@renderer-ui-library/organisms'
import {
  TLocale,
  localeNamespace,
} from '@website-shared-library/machine/i18n/Locale'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React, { useCallback, useContext } from 'react'
import { SalesManagerContext } from 'utils/SalesManager/SalesManagerContext'
import { MachineContext } from 'utils/machine/MachineContext'
import { formatPrice } from 'utils/numbers/formatPrice'
import { useMachineUrl } from 'utils/routing/hooks/useMachineUrl'
import styles from './compareMachineHeader.module.scss'

interface CompareMachineHeaderProps {
  locale: TLocale
  machine: Machine
  removeMachine: (machineGroupId: string) => void
}

export const CompareMachineHeader: React.FC<CompareMachineHeaderProps> =
  React.memo(({ machine, removeMachine, locale }) => {
    const { t } = useTranslation(localeNamespace.common)
    const { getMachineUrl } = useMachineUrl()
    const { salesManager } = useContext(SalesManagerContext)
    const { isMobile } = useMediaQuery()
    const { updateMachine } = useContext(MachineContext)
    const { openRequestInfo } = useContext(ContactWizardContext)

    const onOpenRequestInfoClick = useCallback(() => {
      openRequestInfo()
      updateMachine(machine)
    }, [machine, openRequestInfo, updateMachine])

    const removeMachineFromCompare = useCallback(
      () => removeMachine(machine.machineGroupId),
      [machine.machineGroupId, removeMachine]
    )

    return (
      <div className={styles.imageContainer}>
        <div className={styles.mediaContainer}>
          <Close className={styles.icon} onClick={removeMachineFromCompare} />
          <CompareMachinesImageSlider
            images={
              machine.media.length
                ? machine.media
                : [
                    MachinePlaceholderImageMapper(
                      machine.machineType,
                      'icon'
                    ) as unknown as MachineMedia,
                  ]
            }
            ratio={ImageRatio.Landscape}
            autoPlay={false}
            pagination={false}
            arrows={!isMobile}
          />
        </div>
        <div className={styles.headlineWrapper}>
          <Link href={getMachineUrl(locale, machine)} passHref prefetch={false}>
            <Typography variant='h5' noWrap>
              {machine.name}
            </Typography>

            <Typography variant='body1' noWrap>
              {machine.price ? formatPrice(locale, machine.price) : ''}
            </Typography>
          </Link>
        </div>
        {salesManager?.productInfo && (
          <div className={styles.buttonWrapper}>
            <Button variant='outlined' onClick={onOpenRequestInfoClick}>
              {t(translations.compareMachineContactButtonLabel)}
            </Button>
          </div>
        )}
      </div>
    )
  })

CompareMachineHeader.displayName = 'CompareMachineHeader'
