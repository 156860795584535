import { Machine } from '@internal/utils/machine/Machine'
import { RequestSubmissionFormType } from './TTrackingEvent'
import { tracker } from './tracker'

export const trackConversion = (
  formType: RequestSubmissionFormType,
  machineGroupId?: string,
  machine?: Machine
) => {
  tracker.trackEvent({
    name: 'request-form-submitted',
    request_form_type: formType,
    is_interaction: true,
    machine_group_id: machineGroupId,
  })

  if (machine) {
    tracker.trackEvent({
      name: 'purchase',
      ecommerce: {
        items: [
          {
            request_form_type: formType,
            machine_group_id: machine.machineGroupId,
            item_name: machine.model,
            item_brand: machine.brand,
            price: machine.price ?? undefined,
            item_hp: machine.enginePower ?? undefined,
            item_hours: machine.engineHours ?? undefined,
            item_year: machine.yearOfProduction ?? undefined,
            item_location: machine.locationCountry ?? undefined,
            item_category: machine.machineType,
            is_interaction: true,
          },
        ],
      },
    })
  } else {
    tracker.trackEvent({
      name: 'purchase',
      ecommerce: {
        items: [
          {
            request_form_type: formType,
            is_interaction: true,
          },
        ],
      },
    })
  }

  if (machineGroupId) {
    tracker.trackEvent({
      name: 'Conversion',
      action: 'conversion-tag',
      event: 'conversion',
      ecommerce: {
        purchase: {
          actionField: {
            id: Math.floor(Math.random() * 99999999999) + 1,
          },
          products: [
            {
              id: machineGroupId ?? '',
              price: machine?.price ?? '',
              quantity: 1,
            },
          ],
        },
      },
    })
  }
}
