import { useMediaQuery as MuiUseMediaQuery } from '../../'
import { Font, useCreateTheme } from '../../base/useCreateTheme'

export const useMediaQuery = () => {
  const { theme } = useCreateTheme(Font.Default)
  const isMobile = MuiUseMediaQuery(theme.breakpoints.down('md'))
  const isTablet = MuiUseMediaQuery(theme.breakpoints.down('lg'))
  const isDesktop = MuiUseMediaQuery(theme.breakpoints.up('lg'))

  return {
    isMobile,
    isTablet,
    isDesktop,
  }
}
