import {
  localeNamespace,
  TLocale,
} from '@website-shared-library/machine/i18n/Locale'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { useSearchResultsRouting } from 'blocks/SearchResultsBlock/useSearchResultsRouting'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { defaultLocale } from 'utils/constants'
import { usePrioritizedUrl } from 'utils/routing/urls/usePrioritizedUrl'
import { AvailableMachineTypes } from './AvailableMachineTypes'
import { useMachineTypeName } from './useMachineTypeName'

type TMachineCategoryItem = {
  name: string
  machineType: MachineType
  url: string
}

export type TMachineCategory = {
  name: string
  machines: TMachineCategoryItem[]
}

export const useMachineCategoryMap = () => {
  const { t } = useTranslation(localeNamespace.common)
  const { getMachineTypeName } = useMachineTypeName()
  const { getSearchResultsUrl } = useSearchResultsRouting()
  const { getPrioritizedUrl } = usePrioritizedUrl()
  const { locale } = useRouter()

  const machineCategoryItemWithCategoryPage = useCallback(
    (machineType: MachineType) => ({
      name: getMachineTypeName(machineType),
      machineType,
      url: getPrioritizedUrl({
        machineCategoryPage: {
          data: {
            id: 0,
            attributes: {
              id: '1',
              locale: (locale as TLocale) ?? defaultLocale,
              machineType,
            },
          },
        },
      }) as string,
    }),
    [getMachineTypeName, getPrioritizedUrl, locale]
  )

  const machineCategoryItem = useCallback(
    (machineType: MachineType) => ({
      name: getMachineTypeName(machineType),
      machineType,
      url: getSearchResultsUrl({ machineType }),
    }),
    [getMachineTypeName, getSearchResultsUrl]
  )

  const machineCategoryMap = useMemo(() => {
    const {
      TRACTOR,
      BALER,
      BALE_WRAPPER,
      COMBINE_HARVESTER,
      FORAGE_HARVESTER_SELF_PROPELLED,
      BEET_HARVESTING_EQUIPMENT,
      MOWER,
      POTATO_HARVESTER,
      FERTILISER_SPREADER,
      SPRAYER,
      PRESSES_AND_ROLLER,
      TOPPER,
      ROTARY_RAKE_WINDROWER,
      FORAGING_EQUIPMENT_OTHER,
      CULTIVATOR,
      PLOUGH,
      SEEDBED_COMBINATION,
      DISC_HARROW_AND_COMPACT_DISC,
      POWER_HARROW,
      ...otherMachines
    } = AvailableMachineTypes

    const sortAlphabetically = (
      a: TMachineCategoryItem,
      b: TMachineCategoryItem
    ) => {
      if (a.name === b.name) {
        return 0
      }

      return a.name > b.name ? 1 : -1
    }

    const categorizedMachinesMap: TMachineCategory[] = [
      {
        name: t(translations.headerMainNavCategoryTractors),
        machines: [
          {
            ...machineCategoryItemWithCategoryPage(TRACTOR),
            name: t(translations.headerMainNavCategoryItemAllTractors),
          },
        ].sort((a, b) => sortAlphabetically(a, b)),
      },
      {
        name: t(translations.headerMainNavCategoryHarvesting),
        machines: [
          machineCategoryItemWithCategoryPage(BALER),
          machineCategoryItem(BALE_WRAPPER),
          machineCategoryItemWithCategoryPage(COMBINE_HARVESTER),
          machineCategoryItem(FORAGE_HARVESTER_SELF_PROPELLED),
          machineCategoryItem(BEET_HARVESTING_EQUIPMENT),
          machineCategoryItem(MOWER),
          machineCategoryItemWithCategoryPage(POTATO_HARVESTER),
        ].sort((a, b) => sortAlphabetically(a, b)),
      },
      {
        name: t(translations.headerMainNavCategorySpreadersSprayers),
        machines: [
          machineCategoryItem(FERTILISER_SPREADER),
          machineCategoryItem(SPRAYER),
        ].sort((a, b) => sortAlphabetically(a, b)),
      },
      {
        name: t(translations.headerMainNavCategoryForaging),
        machines: [
          machineCategoryItem(PRESSES_AND_ROLLER),
          machineCategoryItem(TOPPER),
          machineCategoryItem(ROTARY_RAKE_WINDROWER),
          machineCategoryItem(FORAGING_EQUIPMENT_OTHER),
        ].sort((a, b) => sortAlphabetically(a, b)),
      },
      {
        name: t(translations.headerMainNavCategoryTillage),
        machines: [
          machineCategoryItem(CULTIVATOR),
          machineCategoryItem(PLOUGH),
          machineCategoryItem(SEEDBED_COMBINATION),
          machineCategoryItem(DISC_HARROW_AND_COMPACT_DISC),
          machineCategoryItem(POWER_HARROW),
        ].sort((a, b) => sortAlphabetically(a, b)),
      },
      {
        name: t(translations.headerMainNavCategoryOther),
        machines: [
          ...Object.values(otherMachines).map((machineType) => {
            if (machineType === MachineType.TELEHANDLER) {
              return machineCategoryItemWithCategoryPage(machineType)
            }
            return machineCategoryItem(machineType)
          }),
        ].sort((a, b) => sortAlphabetically(a, b)),
      },
    ]

    return categorizedMachinesMap
  }, [t, machineCategoryItemWithCategoryPage, machineCategoryItem])

  return { machineCategoryMap }
}
