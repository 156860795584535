export enum BlockType {
  SimpleTextBlock = 'simple-text',
  CoverBlock = 'cover',
  ContactBlock = 'contact-block',
  SearchCoverBlock = 'search-cover-block',
  SearchResults = 'search-results',
  MachineDetails = 'machine-details',
  MachineOverview = 'machine-overview',
  TextImageSplitBlock = 'text-image-split',
  IconColumnsBlock = 'icon-columns-block',
  MediaColumnsBlock = 'media-columns-block',
  MediaSplitColumnsBlock = 'media-split-columns-block',
  SpecialistColumnsBlock = 'specialist-columns-block',
  MediaGridBlock = 'media-grid-block',
  NumberedFeaturesColumnsBlock = 'numbered-features-columns-block',
  DynamicCookieListBlock = 'cookie-list-block',
  NotFoundBlock = 'not-found',
  SimilarMachinesBlock = 'similar-machines-block',
  PromoteServiceBlock = 'promote-service-block',
  CompareMachinesBlock = 'compare-machines',
  PopularMachineBlock = 'popular-machine-block',
  ServiceBlock = 'service-block',
  TestimonialsBlock = 'testimonial-block',
  MachineryFeedSliderBlock = 'machinery-feed-slider-block',
  AuctionBlock = 'auction-block',
  FaqBlock = 'faq-block',
  CardBannerBlock = 'card-banner',
  HappyCustomerMapBlock = 'happy-customer-map',
  BenefitsBlock = 'benefits-block',
}
