import { KeyboardArrowUp } from '@mui/icons-material'
import { Box } from '@mui/system'
import { Collapse, Typography } from '@renderer-ui-library/mui'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import classNames from 'classnames'
import { FC, ReactNode, memo, useCallback, useState } from 'react'
import styles from './collapseArea.module.scss'

export interface CollapseAreaProps {
  children: ReactNode
  titleComponent?: ReactNode
  title?: string
  small?: boolean
  indentHeader?: boolean
  headerPaddingTop?: 0 | 1 | 2
  headerPaddingBottom?: 1 | 2
  reverseDirection?: boolean
  hasFieldLines?: boolean
  noCollapse?: boolean
  closed?: boolean
}

export const CollapseArea: FC<CollapseAreaProps> = memo((props) => {
  const { isMobile } = useMediaQuery()
  const [open, setOpen] = useState(props.closed ? false : true)
  const toggle = useCallback(() => {
    if (props.noCollapse) return
    setOpen((o) => !o)
  }, [props.noCollapse])

  const headerPaddingTop = props.headerPaddingTop ?? 2
  const headerPaddingBottom = props.headerPaddingBottom ?? 2
  const indentHeader = props.indentHeader ?? true

  return (
    <>
      <div
        className={classNames(styles.collapseHeader, {
          [styles.isClosed]: !open,
          [styles.indentHeader]: indentHeader,
          [styles.paddingTop1]: headerPaddingTop === 1,
          [styles.paddingTop2]: headerPaddingTop === 2,
          [styles.paddingBottom1]: headerPaddingBottom === 1,
          [styles.paddingBottom2]: headerPaddingBottom === 2,
          [styles.reverseDirection]: !!props.reverseDirection,
          [styles.lines]: !!props.hasFieldLines,
          [styles.defaultCursor]: props.noCollapse,
        })}
        onClick={toggle}
      >
        {props.titleComponent
          ? props.titleComponent
          : props.title && (
              <div className={styles.title}>
                <Typography
                  variant={
                    props.small && !props.hasFieldLines
                      ? isMobile
                        ? 'h6'
                        : 'subtitle1'
                      : 'h4'
                  }
                  component={props.small ? 'h6' : 'h3'}
                >
                  {props.title}
                </Typography>
              </div>
            )}
        {!props.noCollapse && (
          <KeyboardArrowUp fontSize={props.small ? 'small' : 'medium'} />
        )}
      </div>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box>{props.children}</Box>
      </Collapse>
    </>
  )
})

CollapseArea.displayName = 'CollapseArea'
