import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import { SystemPageKey } from 'page/SystemPageKey'
import { ParsedUrlQuery } from 'querystring'
import slugify from 'slugify'
import { PageTypeKey } from 'utils/page/PageTypeKey'
import {
  routingTable,
  TCompareMachinesRouteParams,
  TMachineCategoryPageRouteParams,
  TMachineDetailsRouteParams,
  TSearchResultsRouteParams,
} from '../routingTable'
import { getSlugifyOptions } from '../slugs/getSlugifyOptions'
import { TRoute } from '../TRoute'
import { queryParamsToString } from './queryParamsToString'
import { appendTrailingSlash, removeDoubleSlashes } from './removeDoubleSlashes'

type TParams =
  | {
      key: SystemPageKey.StartPage
    }
  | ({
      key: SystemPageKey.SearchResults
    } & TSearchResultsRouteParams)
  | ({
      key: SystemPageKey.PDP
    } & TMachineDetailsRouteParams)
  | {
      key: 'slug'
      slug: string
      queryParams?: ParsedUrlQuery
    }
  | {
      key: 'sitemapUrl'
      slug: string
    }
  | ({
      key: SystemPageKey.CompareMachines
    } & TCompareMachinesRouteParams)
  | {
      key: PageTypeKey.MachineCategoryPage
      machineTypeName: string
      brandSlug?: string | null
      modelSlug?: string | null
      queryParams?: ParsedUrlQuery
    }

export const urlFor = (locale: TLocale, params: TParams | string) => {
  if (typeof params === 'string') {
    const slug = params
    return removeDoubleSlashes(`/${locale}/${slug}/`)
  } else if (params.key === 'slug') {
    const url = removeDoubleSlashes(`/${locale}/${params.slug}/`)
    const queryString =
      params.queryParams && queryParamsToString(params.queryParams)
    return `${url}${queryString && `?${queryString}`}`
  } else if (params.key === PageTypeKey.MachineCategoryPage) {
    const route = routingTable.machineCategoryPageRoutes[
      locale
    ] as TRoute<TMachineCategoryPageRouteParams>

    const machineTypeSlug = slugify(
      params.machineTypeName,
      getSlugifyOptions(locale)
    )
    return route.compile({
      pathParams: {
        machineTypeSlug: machineTypeSlug,
        brandSlug: params.brandSlug ?? undefined,
        modelSlug: params.modelSlug ?? undefined,
      },
      queryParams: params.queryParams ?? {},
    })
  } else if (params.key === 'sitemapUrl') {
    const url = appendTrailingSlash(`${params.slug}`)
    return removeDoubleSlashes(url)
  }

  return routingTable.systemPageRoutes[params.key][locale].compile(params)
}
