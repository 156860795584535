import dynamic from 'next/dynamic'
import { BlockProps } from '../BlockProps'
import { ISimilarMachinesBlockData } from './ISimilarMachinesBlockData'
import { TServerSideSimilarMachinesBlockProps } from './TServerSideSimilarMachinesBlockProps'

export const DynamicSimilarMachinesBlock = dynamic<
  BlockProps<
    ISimilarMachinesBlockData,
    {},
    TServerSideSimilarMachinesBlockProps
  >
>(() => import('./SimilarMachinesBlock').then((md) => md.SimilarMachinesBlock))
