import { ParsedUrlQuery } from 'querystring'
import { urlFor } from '../urls/urlFor'
import { TRedirectGenerator } from './TRedirectGenerator'

export const getRedirectToShortMachineUrl: TRedirectGenerator<
  {
    machineGroupId?: string
  },
  {
    sSearch?: string
  }
> = async ({
  pathParams,
  locale,
  queryParams: { sSearch, ...queryParams },
  ip,
}) => {
  const machineGroupId = pathParams.machineGroupId || sSearch
  if (!machineGroupId) {
    return false
  }
  return urlFor(locale, {
    key: 'slug',
    slug: machineGroupId.toLowerCase(),
    queryParams: queryParams as ParsedUrlQuery,
  })
}
