import { CallOutlined, EmailOutlined } from '@mui/icons-material'
import {
  DynamicContactInfoMobileOverlay,
  DynamicContactPhoneSlide,
  HeadlineSubline,
} from '@renderer-ui-library/molecules'
import { Button, IconButton } from '@renderer-ui-library/mui'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import { ContactWizardContext } from '@renderer-ui-library/organisms'
import { TLocale } from '@website-shared-library/machine/i18n/Locale'
import classNames from 'classnames'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { SystemPageKey } from 'page/SystemPageKey'
import React, {
  SyntheticEvent,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react'
import { TPerson } from 'services/TSalesManagerPayload'
import { urlForSystemPageWithoutParams } from 'utils/routing/urls/urlForSystemPageWithoutParams'
import styles from './successfulUnsubscribeCta.module.scss'

interface Props {
  salesManager: TPerson
  locale: TLocale
}

export const SuccessfulUnsubscribeCta = React.memo(
  ({ salesManager, locale }: Props) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState<boolean>(false)
    const containerRef = useRef(null)
    const { openContactUs } = useContext(ContactWizardContext)
    const { isMobile } = useMediaQuery()

    const toggleOpen = React.useCallback(
      (e: SyntheticEvent) => {
        e.preventDefault()
        setOpen(!open)
      },
      [open]
    )

    const handlePhoneClick = useCallback(() => setOpen(true), [])

    return (
      <div className={styles.wrapper} ref={containerRef}>
        <HeadlineSubline
          headline={t(translations.successfulUnsubscribeCtaHeadline)}
          subline={t(translations.successfulUnsubscribeCtaSubline)}
        />
        <div className={styles.iconOnlyWrapper}>
          <Button
            color='secondary'
            href={urlForSystemPageWithoutParams(
              locale,
              SystemPageKey.SearchResults
            )}
          >
            {t(translations.compareMachinesBrowseMachines)}
          </Button>
          <IconButton
            disableRipple
            disableTouchRipple
            disableFocusRipple
            onClick={handlePhoneClick}
          >
            <CallOutlined className={classNames(styles.icon)} />
          </IconButton>
          <IconButton
            disableRipple
            disableTouchRipple
            disableFocusRipple
            onClick={openContactUs}
          >
            <EmailOutlined className={classNames(styles.icon)} />
          </IconButton>

          <DynamicContactPhoneSlide
            open={open && !isMobile}
            iconOnly
            phone={salesManager?.phone}
            onClose={toggleOpen}
            container={containerRef.current}
          />
        </div>
        {isMobile && (
          <DynamicContactInfoMobileOverlay
            open={open}
            contact={salesManager}
            onCloseClick={toggleOpen}
          />
        )}
      </div>
    )
  }
)

SuccessfulUnsubscribeCta.displayName = 'SuccessfulUnsubscribeCta'
