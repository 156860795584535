import { environment } from 'environment/environment'
import { TTracker } from '../TTracker'
import { PageViewEvent } from '../TTrackingEvent'
import { isCookieConsentBoxClosed } from '../oneTrust'

const push = (event: any) => {
  const global = window as any
  global.dataLayer && global.dataLayer.push(event)
}

const getCookie = (name: string) => {
  const global = window as any
  if (global['document']) {
    var value = '; ' + document.cookie
    var parts = value.split('; ' + name + '=')
    if (parts.length == 2) return parts.pop()?.split(';').shift()
  }
  return null
}

export const gtmTracker: TTracker = {
  isEnabled: () => !!environment.GTM_ID,
  trackPageView(path, title, trackingPageType, meta) {
    if (trackingPageType === null || !isCookieConsentBoxClosed()) {
      return
    }

    let event: PageViewEvent | null = null
    if (trackingPageType === 'machine_category_page') {
      event = {
        name: 'pageview',
        page_location: path,
        page_title: title,
        page_type: 'machine_category_page',
        machine_category_page_filter: meta?.machineCategoryPageFilter,
      }
    } else {
      event = {
        name: 'pageview',
        page_location: path,
        page_title: title,
        page_type: trackingPageType,
        machine_group_id: meta?.machineGroupId,
      }
    }
    if (event) {
      push({
        event: event.name,
        ...event,
      })
    }
  },
  trackEvent(event) {
    if (!isCookieConsentBoxClosed()) {
      return
    }
    push({
      event: event.name,
      ...event,
    })
  },
}
