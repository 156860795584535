import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import { MachineType } from '@website-shared-library/machine/MachineType'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { machineTypeTranslationKeyMapping } from 'utils/machineTypeTranslationKeyMapping'

export const useMachineTypeName = () => {
  const { t } = useTranslation(localeNamespace.common)

  const getMachineTypeName = useCallback(
    (machineType: MachineType, count: number = 0) =>
      t(machineTypeTranslationKeyMapping[machineType], {
        count,
      }) as string,
    [t]
  )

  const lowerCaseMachineTypeName = (machineType: string, locale: TLocale) =>
    !['de'].includes(locale) ? machineType.toLowerCase() : machineType

  return { getMachineTypeName, lowerCaseMachineTypeName }
}
