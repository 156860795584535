import { Dropdown, LinkComponent } from '@renderer-ui-library/atoms'
import { Divider } from '@renderer-ui-library/mui'
import Colors from '@renderer-ui-library/mui/base/colors.module.scss'
import { useMediaQuery } from '@renderer-ui-library/mui/utils/mediaQuery/useMediaQuery'
import { HeaderPhoneNumber } from '@renderer-ui-library/scaffolding/Header/HeaderPhoneNumber/HeaderPhoneNumber'
import { Locales } from '@renderer-ui-library/scaffolding/Locales/Locales'
import { localeNamespace } from '@website-shared-library/machine/i18n/Locale'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import classNames from 'classnames'
import { translations } from 'i18n/translations'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getCustomerCareNumber } from 'services/unauthenticatedBackendClient'
import { TNavigation } from 'utils/navigation/TNavigation'
import { TNavigationEntry } from 'utils/navigation/TNavigationEntry'
import { convertCmsTrackingClickEvent } from 'utils/tracking/convertCmsTrackingEvents'
import { tracker } from 'utils/tracking/tracker'
import { HeaderContentWrapper } from '../HeaderContentWrapper/HeaderContentWrapper'
import { TSearchParams } from '../SearchOverlay/SearchOverlay'
import { DynamicSearchOverlay } from '../SearchOverlay/dymanicSearchOverlay'
import styles from './secondaryNavigation.module.scss'

export type Props = {
  locale: TLocale
  navigation: TNavigation
  searchParams: TSearchParams
}

export const SecondaryNavigation: React.FC<Props> = React.memo(
  ({ locale, navigation, searchParams }) => {
    const { query } = useRouter()
    const { t } = useTranslation(localeNamespace.common)
    const [careNumber, setCareNumber] = useState<string>(
      t(translations.customerServiceNumber)
    )
    const { isMobile } = useMediaQuery()

    const handleClick = useCallback((entry: TNavigationEntry) => {
      if (entry.trackingClickEvent) {
        tracker.trackEvent({
          ...convertCmsTrackingClickEvent(entry.trackingClickEvent),
        })
      }
    }, [])

    useEffect(() => {
      if (query.ip) {
        getCustomerCareNumber(query.ip as string).then(setCareNumber)
      }
    }, [query.ip])

    const mainNavigationEntries = useMemo(
      () => navigation.entries.slice(0, 3),
      [navigation.entries]
    )
    const secondaryNavigationEntries = useMemo(
      () => navigation.entries.slice(3),
      [navigation.entries]
    )

    const mainNavigationEntriesClickHandler = useMemo(
      () => mainNavigationEntries.map((entry) => () => handleClick(entry)),
      [mainNavigationEntries, handleClick]
    )

    const renderSecondaryNavigationEntries = useCallback(
      ({ close }: { close: () => void }) =>
        secondaryNavigationEntries.map((entry) => (
          <div className={styles.moreEntry} key={entry.id}>
            <LinkComponent
              onClick={close}
              href={entry.route}
              external={entry.external}
              textColorVariant='primary'
              prefetch={false}
            >
              {entry.name}
            </LinkComponent>
          </div>
        )),
      [secondaryNavigationEntries]
    )

    return (
      <HeaderContentWrapper>
        <div className={styles.wrapper}>
          <div className={styles.left}>
            <HeaderPhoneNumber number={careNumber} />
          </div>
          <div className={styles.right}>
            <div className={styles.nav}>
              {mainNavigationEntries.map((entry, index) => (
                <div
                  className={classNames(styles.entry, styles.clickable, {
                    [styles.highlight]: entry.highlight,
                  })}
                  key={entry.id}
                >
                  <LinkComponent
                    href={entry.route}
                    external={entry.external}
                    textFontVariant='secondary'
                    prefetch={false}
                    onClick={mainNavigationEntriesClickHandler[index]}
                  >
                    {entry.name}
                  </LinkComponent>
                </div>
              ))}
              {secondaryNavigationEntries.length > 0 && (
                <div className={styles.entry}>
                  <Dropdown
                    label={t(translations.headerSecondaryNavMoreLabel)}
                    textColor={Colors.textInvertedPrimary}
                    variant='secondary'
                    orientation='left'
                    render={renderSecondaryNavigationEntries}
                  />
                </div>
              )}
            </div>
            <Divider
              className={styles.divider}
              color={Colors.textInvertedPrimary}
              orientation='vertical'
              variant='middle'
              flexItem
            />
            <Locales locale={locale} />
            <Divider
              className={styles.divider}
              color={Colors.textInvertedPrimary}
              orientation='vertical'
              variant='middle'
              flexItem
            />
            {!isMobile && <DynamicSearchOverlay searchParams={searchParams} />}
          </div>
        </div>
      </HeaderContentWrapper>
    )
  }
)

SecondaryNavigation.displayName = 'SecondaryNavigation'
